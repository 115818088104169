export function mascaraDDMMAAAA(data: string): string {
  let sanitizedValue = data.replace(/\D/g, ''); // Remove caracteres não numéricos
  if (sanitizedValue.length > 8) {
    sanitizedValue = sanitizedValue.substr(0, 8); // Limita para 8 caracteres
  }
  return sanitizedValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
}

export const mascaraDateRange = (input: string) => {
  // Remove todos os caracteres não numéricos
  const numeros = input.replace(/\D/g, '');

  // Extrai os componentes das datas
  const diaInicio = numeros.slice(0, 2);
  const mesInicio = numeros.slice(2, 4);
  const anoInicio = numeros.slice(4, 8);
  const diaFim = numeros.slice(8, 10);
  const mesFim = numeros.slice(10, 12);
  const anoFim = numeros.slice(12, 16);

  // Formata a data inicial
  let formattedInicio = diaInicio;
  if (mesInicio.length > 0) {
    formattedInicio += `/${mesInicio}`;
  }
  if (anoInicio.length > 0) {
    formattedInicio += `/${anoInicio}`;
  }

  // Formata a data final
  let formattedFim = diaFim;
  if (mesFim.length > 0) {
    formattedFim += `/${mesFim}`;
  }
  if (anoFim.length > 0) {
    formattedFim += `/${anoFim}`;
  }

  // Retorna a data formatada
  if (numeros.length <= 8) {
    return formattedInicio;
  }
  return `${formattedInicio} - ${formattedFim}`;
};

export const mascaraMesAnoDateRange = (input: string) => {
  // Remove todos os caracteres não numéricos
  const numeros = input.replace(/\D/g, '');

  // Extrai os componentes das datas
  const mesInicio = numeros.slice(0, 2);
  const anoInicio = numeros.slice(2, 6);
  const mesFim = numeros.slice(6, 8);
  const anoFim = numeros.slice(8, 12);

  // Formata a data inicial
  let formattedInicio = mesInicio;
  if (anoInicio.length > 0) {
    formattedInicio += `/${anoInicio}`;
  }

  // Formata a data final
  let formattedFim = mesFim;
  if (anoFim.length > 0) {
    formattedFim += `/${anoFim}`;
  }

  // Retorna a data formatada
  if (numeros.length <= 6) {
    return formattedInicio;
  }
  return `${formattedInicio} - ${formattedFim}`;
};

export function mascaraMMAAAA(data: string): string {
  let sanitizedValue = data.replace(/\D/g, ''); // Remove caracteres não numéricos
  if (sanitizedValue.length > 6) {
    sanitizedValue = sanitizedValue.substr(0, 6); // Limita para 6 caracteres
  }
  return sanitizedValue.replace(/(\d{2})(\d{4})/, '$1/$2');
}

export function mascaraNumeros(value: string): string {
  if (value === undefined || value === null) return value;
  return value.toString().replace(/[^\d]+/g, '');
}

export function mascaraNumerosVirgula(value: string): string {
  if (value === undefined || value === null) return value;

  // Remove caracteres que não são números ou vírgulas
  let cleanedValue = value.toString().replace(/[^\d,]+/g, '');

  // Garante que a vírgula só seja adicionada se houver um número antes
  if (cleanedValue.includes(',')) {
    const [beforeComma, ...afterComma] = cleanedValue.split(',');

    // Se não houver números antes da vírgula, adiciona '0'
    cleanedValue = `${beforeComma || '0'},${afterComma.join('')}`;
  }

  // Se começa com uma vírgula, corrige para '0,'
  if (cleanedValue.startsWith(',')) {
    cleanedValue = `0${cleanedValue}`;
  }

  // Permite apenas uma vírgula
  const parts = cleanedValue.split(',');
  cleanedValue = parts.shift()!; // Mantém a parte antes da primeira vírgula
  if (parts.length > 0) {
    cleanedValue += `,${parts.join('')}`; // Adiciona apenas uma vírgula e ignora outras
  }

  return cleanedValue;
}

export function mascaraNumeracaoPtBr(value: string): string {
  /// Remove todos os caracteres que não são dígitos
  const digitsOnly = value.replace(/\D/g, '');

  // Converte a string de dígitos para um número
  const number = parseFloat(digitsOnly);

  // Verifica se o número é válido
  if (Number.isNaN(number)) {
    // Caso não seja um número válido, retorna o valor original
    return value;
  }

  // Divide o número por 100 para ajustar a escala
  const adjustedNumber = number / 100;

  // Formata o número como dinheiro
  const formattedNumber = adjustedNumber.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
}

export function converterParaFloat(valorFormatado: string): number {
  // Remove todos os caracteres que não são dígitos nem o separador decimal
  const cleanedValue = valorFormatado.replace(/[^\d,.-]/g, '');

  // Substitui a vírgula por ponto para obter o formato numérico correto
  const formattedValue = cleanedValue.split('.').join('').replace(',', '.');

  // Converte o valor para float
  const floatValue = parseFloat(formattedValue);

  return floatValue;
}

export const maskCPF = (value: string) => {
  return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '')
    .slice(0, 14)
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export const maskCPFCNPJ = (value: string) => {
  if (value.replace(/[^\d]/g, '').length > 11) {
    return maskCNPJ(value);
  }
  return maskCPF(value);
};

export const phoneNumberMask = (phone: string) => {
  // Remove todos os caracteres que não são números
  const cleaned = phone.replace(/\D/g, '');

  // Aplica a formatação ao número, mesmo que incompleto
  let formattedPhone = cleaned;

  if (cleaned.length >= 2) {
    formattedPhone = `(${cleaned.slice(0, 2)}`;
  }

  if (cleaned.length > 2) {
    formattedPhone += `)${cleaned.slice(2, Math.min(7, cleaned.length))}`;
  }

  if (cleaned.length > 7) {
    formattedPhone += `-${cleaned.slice(7, Math.min(11, cleaned.length))}`;
  }

  return formattedPhone;
};
