/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { CircularProgress, Tooltip } from '@material-ui/core';
import moment from 'moment';
import TextField from '../../../../../_core/_components/text-field';
import Checkbox from '../../../components/checkbox';
import * as S from './styles';

import Button from '../../../components/button';
import { converterParaFloat, mascaraNumeracaoPtBr } from '../../../../../_core/masks';
import api from '../../../../../_core/api';
import { TableRow } from '../../../../configuracoes/parametros/components/table';
import { failure, handleErrors, success } from '../../../../../_core/services/toast';
import { Slip } from '../../types';
import { toReal } from '../../../../../_core/services/formaters';
import { ExpectationReceiptDTO } from './types';
import { parserDTO } from './parser';
import { Modal, SingleSelect } from '../../../../../_core/_components';
import ConfirmationModal from '../../../../../_core/_components/confirmation-modal';

type Props = {
  onClose: () => void;
  onRequestUpdate: () => void;
  boletos: Slip[];
  companyId: number;
};

type TendencyCheck = {
  isTendency: boolean;
};

export type ValuesState = {
  id?: number;
  expectationReceiptRentValue: string;
  expectationReceiptCondoValue: string;
  expectationReceiptFundsValue: string;
  expectationReceiptComercialValue: string;
  adjustmentRentValue: string;
  adjustmentCondoValue: string;
  adjustmentFundsValue: string;
  adjustmentComercialValue: string;
  expectationReceiptLiqRentValue: string;
  expectationReceiptLiqCondoValue: string;
  expectationReceiptLiqFundsValue: string;
  expectationReceiptLiqComercialValue: string;
};

const INITIAL: ValuesState = {
  expectationReceiptRentValue: '',
  expectationReceiptCondoValue: '',
  expectationReceiptFundsValue: '',
  expectationReceiptComercialValue: '',
  adjustmentRentValue: '',
  adjustmentCondoValue: '',
  adjustmentFundsValue: '',
  adjustmentComercialValue: '',
  expectationReceiptLiqRentValue: '',
  expectationReceiptLiqCondoValue: '',
  expectationReceiptLiqFundsValue: '',
  expectationReceiptLiqComercialValue: '',
};

const ModalExpectativa: React.FC<Props> = ({ onClose, boletos, companyId, onRequestUpdate }) => {
  const [expectativaList, setExpectativaList] = useState<TableRow[]>([]);
  const [selectedExpectativaId, setSelectedExpectativaId] = useState<number>();
  const [motivoList, setMotivoList] = useState<TableRow[]>([]);
  const [selectedMotivoId, setSelectedMotivoId] = useState<number>();
  const [isLoading, setLoading] = useState(false);
  const [dataLimite, setDataLimite] = useState('');
  const [tendenciaOriginal, setTendenciaOriginal] = useState(false);
  const [tendencia, setTendencia] = useState(false);
  const [values, setValues] = useState<ValuesState>(INITIAL);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    getValues();
  }, []);

  const getValues = async () => {
    setLoading(true);
    try {
      const resTendency = await api.get<TendencyCheck>(
        `/api/allos/v1/prioritization/tendency/slipNumber/${boletos[0].slipNumber}/companyId/${companyId}`,
      );

      setTendencia(resTendency.data.isTendency);
      setTendenciaOriginal(resTendency.data.isTendency);

      const resExpectation = await api.get<TableRow[]>('/api/allos/v1/prioritization/expectation-receipt/all');
      setExpectativaList(resExpectation.data);

      const resMotivo = await api.get<TableRow[]>('/api/allos/v1/prioritization/unlikely-reason-receipt/all');
      setMotivoList(resMotivo.data);

      const { data } = await api.get<ExpectationReceiptDTO>(
        `/api/allos/v1/prioritization/expectation-receipt/slip?SlipId=${boletos[0].slipId}`,
      );
      if (data) {
        const obj = parserDTO(data);
        setSelectedExpectativaId(data.expectationReceipt.expectationReceiptType.id);
        setSelectedMotivoId(data.expectationReceipt.unlikelyReceiptReason?.id);
        setDataLimite(data.expectationReceipt.expirationDate);
        setValues({ ...obj, id: data.id });
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const limparDados = async () => {
    setLoading(true);
    try {
      setIsConfirmationOpen(false);
      await api.delete(`api/allos/v1/prioritization/expectation-receipt/slip?SlipId=${boletos[0].slipId}`);
      success('Dados apagados com sucesso');
      onClose();
      onRequestUpdate();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const desmarcarTendencia = async () => {
    try {
      await api.put('/api/allos/v1/prioritization/tendency', {
        slips: boletos.map(b => b.slipId),
      });
    } catch (e) {
      failure('Erro ao tentar desmarcar tendência');
    }
  };

  const saveExpectativa = async () => {
    try {
      setLoading(true);

      const payload = {
        expirationDate: dataLimite,
        expectationReceiptTypeId: selectedExpectativaId,
        unlikelyReceiptReasonId: selectedMotivoId,
        slips: boletos.map(b => b.slipId),
        adjustmentRentValue:
          values.adjustmentRentValue.length > 0 ? converterParaFloat(values.adjustmentRentValue) : undefined,
        adjustmentCondoValue:
          values.adjustmentCondoValue.length > 0 ? converterParaFloat(values.adjustmentCondoValue) : undefined,
        adjustmentFundsValue:
          values.adjustmentFundsValue.length > 0 ? converterParaFloat(values.adjustmentFundsValue) : undefined,
        adjustmentComercialValue:
          values.adjustmentComercialValue.length > 0 ? converterParaFloat(values.adjustmentComercialValue) : undefined,
        expectationReceiptRentValue:
          values.expectationReceiptRentValue.length > 0
            ? converterParaFloat(values.expectationReceiptRentValue)
            : undefined,
        expectationReceiptCondoValue:
          values.expectationReceiptCondoValue.length > 0
            ? converterParaFloat(values.expectationReceiptCondoValue)
            : undefined,
        expectationReceiptFundsValue:
          values.expectationReceiptFundsValue.length > 0
            ? converterParaFloat(values.expectationReceiptFundsValue)
            : undefined,
        expectationReceiptComercialValue:
          values.expectationReceiptComercialValue.length > 0
            ? converterParaFloat(values.expectationReceiptComercialValue)
            : undefined,
        expectationReceiptLiqRentValue:
          values.expectationReceiptLiqRentValue.length > 0
            ? converterParaFloat(values.expectationReceiptLiqRentValue)
            : undefined,
        expectationReceiptLiqCondoValue:
          values.expectationReceiptLiqCondoValue.length > 0
            ? converterParaFloat(values.expectationReceiptLiqCondoValue)
            : undefined,
        expectationReceiptLiqFundsValue:
          values.expectationReceiptLiqFundsValue.length > 0
            ? converterParaFloat(values.expectationReceiptLiqFundsValue)
            : undefined,
        expectationReceiptLiqComercialValue:
          values.expectationReceiptLiqComercialValue.length > 0
            ? converterParaFloat(values.expectationReceiptLiqComercialValue)
            : undefined,
      };

      await api.post(`/api/allos/v1/prioritization/expectation-receipt/slip`, payload);

      if (tendenciaOriginal && !tendencia) {
        await desmarcarTendencia();
      }

      setLoading(false);
      success('Salvo com sucesso');
      onClose();
      onRequestUpdate();
    } catch (e) {
      handleErrors(e);
      setLoading(false);
    }
  };

  const expectativaOptions = useMemo(() => {
    return expectativaList.map(e => ({ value: e.id, label: e.description }));
  }, [expectativaList]);

  const motivoOptions = useMemo(() => {
    return motivoList.map(m => ({ value: m.id, label: m.description }));
  }, [motivoList]);

  const content = (
    <S.ModalContent>
      <S.MainBoletoInfo>
        <S.InfoGroup>
          <S.Label>Boleto</S.Label>
          <S.InfoValue>{boletos[0]?.slipNumber}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.Label>Data Vencimento</S.Label>
          <S.InfoValue>{moment(boletos[0]?.dueDate).format('DD/MM/YYYY')}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.Label>Valor</S.Label>
          <S.InfoValue>{toReal(boletos[0]?.slipValue)}</S.InfoValue>
        </S.InfoGroup>
      </S.MainBoletoInfo>
      <S.ExpectativaSections>
        <S.VerticalSection>
          <S.VerticalSectionTittle>Previsão de Recebimento</S.VerticalSectionTittle>
          <S.VerticalInputGroup>
            <S.Label>Data Limite Expectativa</S.Label>
            <TextField
              state={[dataLimite, v => setDataLimite(v)]}
              fullWidth
              size="small"
              type="date"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Status da Expectativa de Recebimento</S.Label>
            <SingleSelect
              controlStyle={{
                fontSize: '14px',
              }}
              state={[
                expectativaOptions.find(e => e.value === selectedExpectativaId),
                option => setSelectedExpectativaId(option?.value as number),
              ]}
              options={expectativaOptions}
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Motivo Improvável Recebimento</S.Label>
            <SingleSelect
              controlStyle={{
                fontSize: '14px',
              }}
              state={[
                motivoOptions.find(e => e.value === selectedMotivoId),
                option => setSelectedMotivoId(option?.value as number),
              ]}
              options={motivoOptions}
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Marcado para Tendência?</S.Label>
            <Tooltip title={!tendenciaOriginal ? 'Não é possível MARCAR, apenas DESMARCAR tendência ' : ''}>
              <span>
                <Checkbox disabled={!tendenciaOriginal} checked={tendencia} change={() => setTendencia(!tendencia)} />
              </span>
            </Tooltip>
          </S.VerticalInputGroup>
        </S.VerticalSection>

        <S.VerticalSection>
          <S.VerticalSectionTittle>Desconto/Ajuste</S.VerticalSectionTittle>
          <S.VerticalInputGroup>
            <S.Label>Aluguel</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.adjustmentRentValue,
                value => setValues({ ...values, adjustmentRentValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Condomínio</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.adjustmentCondoValue,
                value => setValues({ ...values, adjustmentCondoValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Fundo</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.adjustmentFundsValue,
                value => setValues({ ...values, adjustmentFundsValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Comerciais</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.adjustmentComercialValue,
                value => setValues({ ...values, adjustmentComercialValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
        </S.VerticalSection>

        <S.VerticalSection>
          <S.VerticalSectionTittle>Expectativa Recebimento Mês</S.VerticalSectionTittle>
          <S.VerticalInputGroup>
            <S.Label>Aluguel</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptRentValue,
                value => setValues({ ...values, expectationReceiptRentValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Condomínio</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptCondoValue,
                value => setValues({ ...values, expectationReceiptCondoValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Fundo</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptFundsValue,
                value => setValues({ ...values, expectationReceiptFundsValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Comerciais</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptComercialValue,
                value => setValues({ ...values, expectationReceiptComercialValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
        </S.VerticalSection>

        <S.VerticalSection>
          <S.VerticalSectionTittle>Expectativa Recebimento Líquida</S.VerticalSectionTittle>
          <S.VerticalInputGroup>
            <S.Label>Aluguel</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptLiqRentValue,
                value => setValues({ ...values, expectationReceiptLiqRentValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Condomínio</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptLiqCondoValue,
                value => setValues({ ...values, expectationReceiptLiqCondoValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Fundo</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptLiqFundsValue,
                value => setValues({ ...values, expectationReceiptLiqFundsValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
          <S.VerticalInputGroup>
            <S.Label>Comerciais</S.Label>
            <TextField
              pattern={mascaraNumeracaoPtBr}
              state={[
                values.expectationReceiptLiqComercialValue,
                value => setValues({ ...values, expectationReceiptLiqComercialValue: value.toString() }),
              ]}
              fullWidth
              size="small"
              type="data"
              variant="outlined"
            />
          </S.VerticalInputGroup>
        </S.VerticalSection>
      </S.ExpectativaSections>
    </S.ModalContent>
  );

  const isDisabled = () => {
    const checkValues = Object.values(values).every(value => value === '');
    if (checkValues) return true;
    const statusExpectativaRecebimento = expectativaOptions.find(e => e.value === selectedExpectativaId);
    if (statusExpectativaRecebimento?.label === 'Improvável Recebimento' && selectedMotivoId === undefined) return true;
    return dataLimite?.length !== 10 || !selectedExpectativaId;
  };

  return (
    <>
      <Modal
        size="lg"
        title="Expectativa de Recebimento"
        onClose={onClose}
        content={
          isLoading ? (
            <S.LoaderWrapper>
              <CircularProgress />
            </S.LoaderWrapper>
          ) : (
            content
          )
        }
        actionItems={[
          <Button onClick={() => setIsConfirmationOpen(true)} variant="text" color="secondary" text="Limpar" />,
          <Button onClick={saveExpectativa} startIcon={<SaveIcon />} text="Salvar" disabled={isDisabled()} />,
        ]}
      />
      <ConfirmationModal
        handleCancel={() => setIsConfirmationOpen(false)}
        handleOk={limparDados}
        open={isConfirmationOpen}
        text="Deseja apagar os dados salvos?"
      />
    </>
  );
};

export default ModalExpectativa;
