import React, { useEffect, useState } from 'react';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AxiosResponse } from 'axios';
import Table, { TableRow } from './components/table';
import Button from './components/button';
import * as S from './styles';
import api from '../../../_core/api';
import DetailsModal from './components/DetailsModal';
import { failure, success } from '../../../_core/services/toast';

export type OptionTypes = 'tipoNeg' | 'expecativa' | 'motivoImprovavel' | undefined;

export const Parametros = (): JSX.Element => {
  const [novaOpcao, setNovaOpcao] = useState<OptionTypes | undefined>();
  const [tiposNegociacao, setTiposNegociacao] = useState<TableRow[]>();
  const [expectativaList, setExpectativaList] = useState<TableRow[]>();
  const [motivoList, setMotivoList] = useState<TableRow[]>();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const resTypeNeg = await api.get<TableRow[]>('/api/allos/v1/prioritization/type-negotiation/all');
      setTiposNegociacao(resTypeNeg.data);

      const resExpectation = await api.get<TableRow[]>('/api/allos/v1/prioritization/expectation-receipt/all');
      setExpectativaList(resExpectation.data);

      const resMotivo = await api.get<TableRow[]>('/api/allos/v1/prioritization/unlikely-reason-receipt/all');
      setMotivoList(resMotivo.data);

      setLoading(false);
    } catch (e) {
      failure('Falha ao tentar carregar dados. Entre em contato com a equipe técnica.');
      setLoading(false);
    }
  };

  const updateLists = async (deletePromise?: Promise<AxiosResponse<any>>) => {
    try {
      setLoading(true);
      if (deletePromise) {
        await deletePromise;
      }
      setNovaOpcao(undefined);
      await getData();
      success('Ação executada com sucesso');
    } catch (e) {
      failure('Falha ao tentar realizar a ação. Entre em contato com a equipe técnica.');
      setLoading(false);
    }
  };

  return (
    <S.Wrapper>
      {Boolean(novaOpcao) && (
        <DetailsModal onUpdate={updateLists} type={novaOpcao} onClose={() => setNovaOpcao(undefined)} />
      )}
      <S.ParamContainer>
        {isLoading ? (
          <S.LoaderContainer>
            <CircularProgress />
          </S.LoaderContainer>
        ) : (
          <>
            <S.ContainerHeader>
              <S.ParamTitle>ESTÁGIOS DE NEGOCIAÇÃO</S.ParamTitle>
              <Button
                onClick={() => setNovaOpcao('tipoNeg')}
                size="small"
                startIcon={<PostAddIcon />}
                text="Nova Opção"
              />
            </S.ContainerHeader>
            <Table paramType="tipoNeg" onUpdate={p => updateLists(p)} tableRows={tiposNegociacao} />
          </>
        )}
      </S.ParamContainer>

      <S.ParamContainer>
        {isLoading ? (
          <S.LoaderContainer>
            <CircularProgress />
          </S.LoaderContainer>
        ) : (
          <>
            <S.ContainerHeader>
              <S.ParamTitle>EXPECTATIVA DE RECEBIMENTO</S.ParamTitle>
              <Button
                onClick={() => setNovaOpcao('expecativa')}
                size="small"
                startIcon={<PostAddIcon />}
                text="Nova Opção"
              />
            </S.ContainerHeader>
            <Table paramType="expecativa" onUpdate={p => updateLists(p)} tableRows={expectativaList} />
          </>
        )}
      </S.ParamContainer>

      <S.ParamContainer>
        {isLoading ? (
          <S.LoaderContainer>
            <CircularProgress />
          </S.LoaderContainer>
        ) : (
          <>
            <S.ContainerHeader>
              <S.ParamTitle>MOTIVO IMPROVÁVEL RECEBIMENTO</S.ParamTitle>
              <Button
                onClick={() => setNovaOpcao('motivoImprovavel')}
                size="small"
                startIcon={<PostAddIcon />}
                text="Nova Opção"
              />
            </S.ContainerHeader>
            <Table paramType="motivoImprovavel" onUpdate={p => updateLists(p)} tableRows={motivoList} />
          </>
        )}
      </S.ParamContainer>
    </S.Wrapper>
  );
};
