import React from 'react';
import * as S from './styles';
import { Detalhe, Resumo } from './types';

const GRUPOS = [3, 4, 5, 8, 9, 10, 14, 15, 16];

export function Table({ data, visaoResumo }: { data: Resumo; visaoResumo: string }): JSX.Element {
  const formatTableRow = (item: Detalhe, index: number) => {
    let prefix = '';
    let sufix = '';
    if (item.descricao.descricao.includes('R$')) prefix = 'R$ ';
    if (item.descricao.descricao.includes('%')) sufix = '%';
    return (
      <tr
        key={item.id}
        style={{
          background: GRUPOS.includes(index) ? '#008c90' : 'white',
          color: GRUPOS.includes(index) ? 'white' : 'black',
        }}
      >
        <td>{item.descricao.descricao}</td>
        <td>{prefix + item.valorMensalAluguel?.toLocaleString('pt-br') + sufix}</td>
        <td>{prefix + item.valorMensalCondominio?.toLocaleString('pt-br') + sufix}</td>
        <td>{prefix + item.valorMensalFundo?.toLocaleString('pt-br') + sufix}</td>
        <td>{prefix + item.valorMensalComerciais?.toLocaleString('pt-br') + sufix}</td>
        <td>{prefix + item.valorAnualAluguel?.toLocaleString('pt-br') + sufix}</td>
        <td>{prefix + item.valorAnualCondominio?.toLocaleString('pt-br') + sufix}</td>
      </tr>
    );
  };

  return (
    <S.TableContainer>
      <thead>
        <tr>
          <th rowSpan={2}>RESUMO</th>
          <th colSpan={4}>{visaoResumo.toUpperCase()}</th>
          <th colSpan={2}>INADIMPLÊNCIA YTD</th>
        </tr>
        <tr>
          <th>ALUGUEL</th>
          <th>CONDOMÍNIO</th>
          <th>FUNDO</th>
          <th>COMERCIAIS</th>
          <th>ALUGUEL</th>
          <th>CONDOMÍNIO</th>
        </tr>
      </thead>
      <tbody>{data.map(formatTableRow)}</tbody>
    </S.TableContainer>
  );
}
