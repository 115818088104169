import React, { useRef, useState } from 'react';
import FileSaver from 'file-saver';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import _ from 'lodash';
import * as S from './styles';
import BoletoTable from '../../components/table';
import { Button } from '../../../../../_core/_components/button';
import Desistencia from './Desistencia';
import api from '../../../../../_core/api';
import { failure, handleErrors, success } from '../../../../../_core/services/toast';
import { AjuizamentoDetalhes } from '../../types';
import Excecao, { SubmitExcecao } from './Excecao';
import { Boleto } from './types';

type Props = {
  ajuizamentoDetalhes: AjuizamentoDetalhes;
  onUpdateAjuizamento: (a: AjuizamentoDetalhes) => void;
  setBoletos: React.Dispatch<React.SetStateAction<Boleto[] | undefined>>;
};

const BoletosTitulos: React.FC<Props> = ({ ajuizamentoDetalhes, onUpdateAjuizamento, setBoletos }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [desistenciaOpen, setDesistenciaOpen] = useState(false);
  const [excecaoOpen, setExcecaoOpen] = useState(false);
  const [isPanelExpanded, setIsPanelExpanded] = useState(true);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const salvarDesistencia = async (desc: string, numeroChamado: string) => {
    setIsLoading(true);
    try {
      await api.put(`/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes?.idAcaoAjuizamento}/desistencia`, {
        motivo: desc,
        numeroChamado,
      });

      success('Salvo com sucesso');
      setDesistenciaOpen(false);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
    setDesistenciaOpen(false);
  };

  const salvarExcecao = async (excecaoObj: SubmitExcecao) => {
    setIsLoading(true);
    try {
      await api.put(`/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes?.idAcaoAjuizamento}/excecao`, {
        idJustificativa: excecaoObj.justificativa?.value,
        numeroChamado: excecaoObj.numeroChamado,
        observacao: excecaoObj.obs,
      });

      success('Salvo com sucesso');
      setExcecaoOpen(false);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
    setExcecaoOpen(false);
  };

  const downloadArquivoCSV = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes.idAcaoAjuizamento}/titulos/csv`,
        {
          responseType: 'arraybuffer',
        },
      );
      const blob = new Blob([response.data], {
        type: 'text/csv',
      });
      FileSaver.saveAs(blob, response.headers.filename);
      success('Arquivo baixado');
    } catch (e) {
      failure('Erro ao tentar fazer download');
    }
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    // eslint-disable-next-line no-unused-expressions
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setIsLoading(true);
      try {
        await api.post(
          `/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes.idAcaoAjuizamento}/titulos/csv`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        success('Arquivo enviado com sucesso.');
      } catch (error) {
        failure('Erro ao enviar o arquivo:');
      }
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const atualizarSaldo = async (changedBoletos: Boleto[]) => {
    setIsLoading(true);
    try {
      const titulos: any = [];

      if (changedBoletos) {
        changedBoletos.forEach(bol => {
          if (bol.titulos) {
            bol.titulos.forEach(titulo => {
              titulos.push({
                idTitulo: titulo.idTitulo,
                considerar: titulo.isSelected === true,
              });
            });
          }
        });
      }

      await api.put(`/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes.idAcaoAjuizamento}/titulos`, titulos);
      setIsPanelExpanded(true);
      onUpdateAjuizamento(ajuizamentoDetalhes);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      failure('Erro ao tentar atualizar saldo.');
    }
  };

  const isChamadoGuiaPreenchido = () => {
    if (ajuizamentoDetalhes.guias.find(g => g.numeroChamadoAjuizamento)) {
      return true;
    }

    return false;
  };

  return (
    <S.Wrapper>
      {desistenciaOpen && (
        <Desistencia onSubmitDesistencia={salvarDesistencia} onClose={() => setDesistenciaOpen(false)} />
      )}
      {excecaoOpen && <Excecao onSubmitExcecao={salvarExcecao} onClose={() => setExcecaoOpen(false)} />}

      <Accordion expanded={isPanelExpanded} key={1} onChange={(_, expanded) => null}>
        <AccordionSummary
          onClick={() => setIsPanelExpanded(!isPanelExpanded)}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <S.AccordionGroup>
            <S.AccordionLabel>Contrato: </S.AccordionLabel>
            <S.AccordionValue>{ajuizamentoDetalhes.numeroContrato}</S.AccordionValue>
          </S.AccordionGroup>
          <S.AccordionGroup>
            <S.AccordionLabel>LUC: </S.AccordionLabel>
            <S.AccordionValue>{ajuizamentoDetalhes.luc}</S.AccordionValue>
          </S.AccordionGroup>
          <S.AccordionGroup>
            <S.AccordionLabel>Objeto Contrato: </S.AccordionLabel>
            <S.AccordionValue>{ajuizamentoDetalhes.objeto}</S.AccordionValue>
          </S.AccordionGroup>
          <S.AccordionGroup>
            <S.AccordionLabel>Tipo Dívida: </S.AccordionLabel>
            <S.AccordionValue>{ajuizamentoDetalhes.tipoDivida}</S.AccordionValue>
          </S.AccordionGroup>
          <S.AccordionGroup>
            <S.AccordionLabel>Ação Cabível: </S.AccordionLabel>
            <S.AccordionValue>{ajuizamentoDetalhes.tipoAcao}</S.AccordionValue>
          </S.AccordionGroup>
        </AccordionSummary>
        <AccordionDetails>
          <S.DetailsConteiner>
            <S.DetailsHeading>
              <S.DetailsText>
                Use as setas no início de cada linha para expandir e ver detalhes. Atualize juros, multa e correção
                usando os botões ao lado.
              </S.DetailsText>
              <S.DetailsActions>
                <Button
                  styles={S.buttonStyles}
                  startIcon={<GetAppIcon />}
                  onClick={() => downloadArquivoCSV()}
                  text="Baixar Arquivo"
                />
                <Button
                  styles={S.buttonStyles}
                  startIcon={<PublishIcon />}
                  onClick={() => handleButtonClick()}
                  text="Subir Arquivo"
                  variant="outlined"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </S.DetailsActions>
            </S.DetailsHeading>
            <BoletoTable
              isLoading={isLoading}
              ajuizamentoDetalhes={ajuizamentoDetalhes}
              onChangeBoletos={setBoletos}
              onRequestUpdateSaldo={newBols => atualizarSaldo(newBols)}
            />
          </S.DetailsConteiner>
        </AccordionDetails>
      </Accordion>
      <S.ActionsWrapper>
        <S.Actions>
          <S.ActionLabel>Outras ações</S.ActionLabel>
          <S.ActionRelatorios>
            <S.ActionButtons>
              <Button
                disabled={isChamadoGuiaPreenchido()}
                variant="outlined"
                onClick={() => setDesistenciaOpen(true)}
                text="Desistência da ação"
              />
              <Button
                disabled={isChamadoGuiaPreenchido()}
                variant="outlined"
                onClick={() => setExcecaoOpen(true)}
                text="Inclusão como exceção"
              />
            </S.ActionButtons>
          </S.ActionRelatorios>
        </S.Actions>
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

export default BoletosTitulos;
