import React from 'react';

const InadIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4841_10719)">
        <path
          d="M17.5 6.45924V4.55924C17.5 3.64258 16.75 2.89258 15.8333 2.89258H4.16667C3.24167 2.89258 2.5 3.64258 2.5 4.55924V16.2259C2.5 17.1426 3.24167 17.8926 4.16667 17.8926H15.8333C16.75 17.8926 17.5 17.1426 17.5 16.2259V14.3259C17.9917 14.0342 18.3333 13.5092 18.3333 12.8926V7.89258C18.3333 7.27591 17.9917 6.74258 17.5 6.45924ZM16.6667 7.89258V12.8926H10.8333V7.89258H16.6667ZM4.16667 16.2259V4.55924H15.8333V6.22591H10.8333C9.91667 6.22591 9.16667 6.97591 9.16667 7.89258V12.8926C9.16667 13.8092 9.91667 14.5592 10.8333 14.5592H15.8333V16.2259H4.16667Z"
          fill="#00616F"
        />
        <path
          d="M13.333 11.6426C14.0234 11.6426 14.583 11.0829 14.583 10.3926C14.583 9.70222 14.0234 9.14258 13.333 9.14258C12.6427 9.14258 12.083 9.70222 12.083 10.3926C12.083 11.0829 12.6427 11.6426 13.333 11.6426Z"
          fill="#00616F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4841_10719">
          <rect width="20" height="20" fill="white" transform="translate(0 0.392578)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InadIcon;
