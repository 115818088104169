export const STATUSES = [
  {
    id: 1,
    value: 'AGUARDANDO_QA_LIBERAR_PARA_O_SHOPPING',
    label: 'Pendente Envio Régua',
  },
  {
    id: 2,
    value: 'PENDENTE_RECOMENDACAO_EQUIPE_SHOPPING',
    label: 'Pendente Análise Shopping',
  },
  {
    id: 3,
    value: 'PENDENTE_APROVACAO_SUPERINTENDENTE_SHOPPING',
    label: 'Pendente Aprovação Shopping',
  },
  {
    id: 4,
    value: 'PENDENTE_RECOMENDACAO_EQUIPE_CORPORATIVA',
    label: 'Pendente Análise Cobrança Corporativa',
  },
  {
    id: 5,
    value: 'PENDENTE_APROVACAO_SUPERINTENDENTE_HOLDING',
    label: 'Pendente Aprovação Cobrança Corporativa',
  },
  {
    id: 6,
    value: 'PENDENTE_APROVACAO_EXCECAO_KA',
    label: 'Pendente Aprovação Régua Cobrança',
  },
  {
    id: 7,
    value: 'PENDENTE_DE_ENVIO',
    label: 'Pendente Envio Serasa Régua Cobrança',
  },
  {
    id: 8,
    value: 'FINALIZADO',
    label: 'Finalizado',
  },
  {
    id: 9,
    value: 'APROVADOR_DIRETOR_SHOPPING',
    label: 'Aprovador Diretor Shopping',
  },
  {
    id: 10,
    value: 'APROVADOR_DIRETOR_HOLDING',
    label: 'Aprovador Diretor Holding',
  },
];
