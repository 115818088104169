import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import api from '../../../../_core/api';
import { handleErrors, success } from '../../../../_core/services/toast';
import { Button, Checkbox, Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { converterParaFloat, mascaraNumeracaoPtBr, mascaraNumeros } from '../../../../_core/masks';

import * as S from '../styles';
import { MinimumRequirement } from './types';
import Confirmation from '../../../../_core/_components/confirmation';

type Props = {
  onClose: () => void;
  refresh: () => void;
  selectedRegister: MinimumRequirement | null;
  tipoDividaOptions: Option[];
  tipoAcaoOptions: Option[];
};

const Modal: React.FC<Props> = ({ onClose, refresh, selectedRegister, tipoDividaOptions, tipoAcaoOptions }: Props) => {
  const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [divMinTotal, setDivMinTotal] = useState<string>(
    selectedRegister
      ? selectedRegister.divMinTotal.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          currency: 'BRL',
          style: 'currency',
        })
      : 'R$ 0,00',
  );
  const [divMinMes, setDivMinMes] = useState<string>(
    selectedRegister
      ? selectedRegister.divMinMes.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          currency: 'BRL',
          style: 'currency',
        })
      : 'R$ 0,00',
  );
  const [inicioAgingEmAnos, setInicioAgingEmAnos] = useState<string>(
    selectedRegister?.inicioAgingEmAnos.toString() ?? '',
  );
  const [fimAgingEmDias, setFimAgingEmDias] = useState<string>(selectedRegister?.fimAgingEmDias.toString() ?? '');
  const [tipoDivida, setTipoDivida] = useState<Option | undefined>(
    tipoDividaOptions.find(e => e.value === selectedRegister?.idTipoDivida) ?? undefined,
  );
  const [tipoAcao, setTipoAcao] = useState<Option | undefined>(
    tipoAcaoOptions.find(e => e.value === selectedRegister?.idTipoAcao) ?? undefined,
  );
  const [boletosAbertos, setBoletosAbertos] = useState<string>(selectedRegister?.boletosAbertos.toString() ?? '');

  const [apenasContratosAtivos, setApenasContratosAtivos] = useState(selectedRegister?.apenasContratosAtivos);
  const [requerAcordoAutos, setRequerAcordoAutos] = useState(selectedRegister?.requerAcordoAutos);
  const [deveIncluirBoletosAdm, setDeveIncluirBoletosAdm] = useState(selectedRegister?.deveIncluirBoletosAdm);

  const save = async (ativo = true) => {
    setLoading(true);
    try {
      const payload = {
        idTipoAcao: tipoAcao?.value,
        idTipoDivida: tipoDivida?.value,
        divMinTotal: converterParaFloat(divMinTotal),
        divMinMes: converterParaFloat(divMinMes),
        inicioAgingEmAnos,
        fimAgingEmDias,
        boletosAbertos,
        apenasContratosAtivos,
        requerAcordoAutos,
        deveIncluirBoletosAdm,
        ativo: !!ativo,
      };
      if (selectedRegister) {
        await api.put(`/parametros/ajuizamento/requisitos-minimos/${selectedRegister.id}`, {
          ...selectedRegister,
          ...payload,
        });
      } else {
        await api.post('/parametros/ajuizamento/requisitos-minimos', {
          ...payload,
          ativo: true,
        });
      }
      success('Salvo com sucesso.');
      onClose();
      refresh();
      return;
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const removeRegister = async () => {
    try {
      if (selectedRegister) {
        await api.delete(`/parametros/ajuizamento/requisitos-minimos/${selectedRegister.id}`, {});
        success('Removido com sucesso.');
        onClose();
        refresh();
      }
    } catch (e) {
      handleErrors(e);
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <Confirmation
        handleOK={() => removeRegister()}
        open={confirmationOpen}
        close={() => setConfirmationOpen(false)}
      />
      <S.CloseAction onClick={onClose}>
        <CloseIcon />
      </S.CloseAction>
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <SingleSelect
                state={[tipoDivida, setTipoDivida]}
                placeholder="Tipo de dívida"
                options={tipoDividaOptions}
              />,
              <SingleSelect state={[tipoAcao, setTipoAcao]} placeholder="Tipo de Ação" options={tipoAcaoOptions} />,
              <Input
                pattern={mascaraNumeracaoPtBr}
                placeholder="Div. Min. Total"
                label="Div. Min. Total"
                state={[divMinTotal, setDivMinTotal]}
              />,
              <Input pattern={mascaraNumeracaoPtBr} label="Div. Min. Mês" state={[divMinMes, setDivMinMes]} />,
              <Input
                pattern={mascaraNumeros}
                label="Aging início (anos)"
                state={[inicioAgingEmAnos, setInicioAgingEmAnos]}
              />,
              <Input pattern={mascaraNumeros} label="Aging fim (dias)" state={[fimAgingEmDias, setFimAgingEmDias]} />,
              <Input pattern={mascaraNumeros} label="Boletos abertos" state={[boletosAbertos, setBoletosAbertos]} />,
            ]}
          />
          <S.FormRow>
            <Checkbox
              onClick={() => setApenasContratosAtivos(!apenasContratosAtivos)}
              checked={!!apenasContratosAtivos}
              label="Contratos inativos"
            />
            <Checkbox
              onClick={() => setRequerAcordoAutos(!requerAcordoAutos)}
              checked={!!requerAcordoAutos}
              label="Desconsiderar se houver acordo nos autos"
            />
            <Checkbox
              onClick={() => setDeveIncluirBoletosAdm(!deveIncluirBoletosAdm)}
              checked={!!deveIncluirBoletosAdm}
              label="Boleto admin"
            />
          </S.FormRow>
          <S.Actions>
            <Button
              variant="outlined"
              onClick={() => setConfirmationOpen(true)}
              text="Excluir"
              disabled={loading || !selectedRegister}
            />
            <Button startIcon={<SaveIcon />} onClick={save} text="Salvar" disabled={loading} />
          </S.Actions>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
