import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';

import Checkbox from './checkbox';
import { toReal } from '../../../../_core/services/formaters';
import { Titulo } from '../detalhamento/BoletosTitulos/types';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      color: '#000',
      fontWeight: 'bold',
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
    root: {
      padding: '4px',
      borderBottom: 'none',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {},
  }),
)(TableRow);

const StyledHeaderTableRow = withStyles(() =>
  createStyles({
    root: {
      '& th:nth-of-type(1)': {},
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    margin: '16px 0px',
  },
  checkbox: {
    padding: '0 9px',
  },
  smCol: {
    width: '40px',
  },
  mdCol: {
    width: '150px',
    '@media (max-width: 1439px)': {
      width: '120px',
    },
  },
  lgCol: {
    width: '170px',
  },
});

type Props = {
  titulos?: Titulo[];
  onToggleTransacao: (transacao: Titulo) => void;
  hasFilledChamado: boolean;
};

const CustomizedTables: React.FC<Props> = ({ titulos, onToggleTransacao, hasFilledChamado }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <StyledHeaderTableRow>
          <StyledTableCell className={classes.smCol} />
          <StyledTableCell>Nº Documento</StyledTableCell>
          <StyledTableCell>Tipo Movimento</StyledTableCell>
          <StyledTableCell>Valor Transação</StyledTableCell>
          <StyledTableCell>Data de Vencimento</StyledTableCell>
          <StyledTableCell>Juros</StyledTableCell>
          <StyledTableCell>Multa</StyledTableCell>
          <StyledTableCell>Correção Monetária</StyledTableCell>
          <StyledTableCell>Juros(%)</StyledTableCell>
          <StyledTableCell>Multa(%)</StyledTableCell>
          <StyledTableCell>Índice</StyledTableCell>
        </StyledHeaderTableRow>
      </TableHead>
      <TableBody>
        {titulos?.map(row => (
          <>
            <StyledTableRow>
              <StyledTableCell>
                <Checkbox
                  disabled={hasFilledChamado}
                  className={classes.checkbox}
                  size="small"
                  change={() => onToggleTransacao(row)}
                  checked={row.isSelected || false}
                />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.numeroTitulo}
              </StyledTableCell>
              <StyledTableCell>{row.tipoMovimento}</StyledTableCell>
              <StyledTableCell>{toReal(row.valorAberto)}</StyledTableCell>
              <StyledTableCell>{moment(row.dataVencimento).format('DD/MM/YYYY')}</StyledTableCell>
              <StyledTableCell>
                {row.valJuros?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </StyledTableCell>
              <StyledTableCell>{row.valMulta && toReal(row.valMulta)}</StyledTableCell>
              <StyledTableCell>{row.valCorrecao && toReal(row.valCorrecao)}</StyledTableCell>
              <StyledTableCell>{row.percentualJuros}</StyledTableCell>
              <StyledTableCell>{row.percentualMulta}</StyledTableCell>
              <StyledTableCell>{row.indice}</StyledTableCell>
            </StyledTableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomizedTables;
