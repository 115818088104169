import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { CircularProgress, IconButton } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { Title } from './styles';
import { Recomendation } from './types';
import { verificaTamanhoEmBytes } from '../../../_core/services/permissions';
import { Button, Input, SingleSelect, Modal as CustomModal, Form, UploadFile, Table } from '../../../_core/_components';
import { handleErrors } from '../../../_core/services/toast';
import api from '../../../_core/api';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
  onClear: () => void;
  onDownloadAnexo: (idAnexo: number, nomeArquivo: string) => void;
  values: Recomendation;
  justificativas: any[];
};

const Modal: React.FC<ModalProps> = ({
  onClose,
  onSubmit,
  onClear,
  onDownloadAnexo,
  values,
  justificativas,
}: ModalProps) => {
  const [loading, setLoading] = useState(true);
  const [justificativa, setJustificativa] = useState(values.justificativaId);
  const [observacao, setObservacao] = useState(values.observacao ?? '');
  const [nrChamado, setNrChamado] = useState(values.numChamado ?? '');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [historico, setHistorico] = useState<any[]>([]);

  useEffect(() => {
    const getHistorico = async () => {
      try {
        const { data } = await api.get(`workflow/ajuizamento/acao/${values.id}/historico-recomendacao`);
        setHistorico(data.reverse());
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    };
    getHistorico();
  }, []);

  const disabled = useMemo(() => false, []);

  const onFileChange = (value: File) => {
    const blob = value;
    if (blob === undefined) return;
    if (verificaTamanhoEmBytes(blob!.size)) {
      setFileSizeError(true);
      setFile(undefined);
      return;
    }
    setFileSizeError(false);
    setFile(blob);
  };

  const justificativasOptions = useMemo(() => justificativas.map(e => ({ ...e, value: e.id, label: e.descricao })), [
    justificativas,
  ]);

  const renderedData = useMemo(() => {
    if (historico.length > 0) {
      return historico.map(e => ({
        ...e,
        motivo: justificativas.find(j => j.id === e.idMotivo)?.descricao,
        dataPtBr: moment(e.dataRecomendacao).format('DD/MM/YYYY HH:mm'),
        anexo: e.idAjzDocumentoAnexado && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => onDownloadAnexo(e.idAjzDocumentoAnexado, e.nomeArquivo)}
          >
            <CloudDownload />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [historico]);

  const form = (
    <>
      <Form
        items={[
          <SingleSelect
            state={[
              justificativasOptions.find(e => e.value === justificativa),
              option => setJustificativa(option?.value as string),
            ]}
            options={justificativasOptions}
            placeholder="Justificativas"
          />,
          <Input label="Observação" state={[observacao, setObservacao]} disabled={disabled} />,
          <Input label="Número do chamado" state={[nrChamado, setNrChamado]} disabled={disabled} />,
          <UploadFile label="Anexo" state={[file, value => onFileChange(value as File)]} />,
        ]}
        submitButton={fileSizeError && <Title>O arquivo não pode exceder 1MB.</Title>}
      />
      {loading ? (
        <div style={{ padding: '0.1rem', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Table
          columns={[
            { key: 'motivo', orderable: true, label: 'Motivo justificativa' },
            { key: 'numeroChamdo', orderable: true, label: 'Número chamado' },
            { key: 'observacaoJustificativa', orderable: true, label: 'Observação' },
            { key: 'anexo', orderable: false, label: 'Anexo' },
            { key: 'dataPtBr', orderable: true, label: 'Data' },
            { key: 'username', orderable: true, label: 'Usuário' },
          ]}
          data={renderedData}
        />
      )}
    </>
  );

  return (
    <CustomModal
      title="Justificativa"
      onClose={onClose}
      content={form}
      actionItems={[
        <Button onClick={onClear} text="Limpar" variant="outlined" disabled={!values.justificativaId} />,
        <Button
          disabled={fileSizeError || !justificativa}
          onClick={() => onSubmit({ justificativa, observacao, file, nrChamado })}
          text="Salvar"
        />,
      ]}
    />
  );
};

export default Modal;
