import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import api from '../../../_core/api';
import { Button, Modal, Switch, Table } from '../../../_core/_components';
import { Recomendation } from '../workflow-ajuizamento/types';
import { toReal } from '../../../_core/services/formaters';
import * as S from './styles';
import { failure, success } from '../../../_core/services/toast';

type Props = {
  onClose: () => void;
  open: boolean;
  shoppingId?: number;
};

const DetailsModal: React.FC<Props> = ({ onClose, open, shoppingId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Recomendation[]>([]);

  useEffect(() => {
    if (shoppingId) {
      getData();
    }
  }, [shoppingId]);

  const getData = async () => {
    setLoading(true);
    const { data: recData } = await api.get<Recomendation[]>('/workflow/ajuizamento/unpaged', {
      params: {
        shoppingIds: shoppingId,
        stepWorkflowCode: 'AGUARDANDO_LIBERACAO',
      },
    });
    setData(recData);
    setLoading(false);
  };

  const onSwitchAcao = (idAcao: number) => {
    const newData = data.map(d => {
      if (d.id === idAcao) {
        return {
          ...d,
          seguiraParaWorkflow: !d.seguiraParaWorkflow,
        };
      }
      return d;
    });

    setData(newData);
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(e => ({
        ...e,
        numContrato: e.numeroContrato,
        luc: e.contratoLuc,
        nomeFantasia: e.contratoNomeFantasia,
        razaoSocial: e.contratoRazaoSocial,
        saldoAberto: e.saldoAberto ? toReal(e.saldoAberto) : '-',
        switch: <Switch state={[!!e.seguiraParaWorkflow, () => onSwitchAcao(e.id)]} hideLabel />,
      }));
    }
    return [];
  }, [data]);

  const save = async () => {
    try {
      setLoading(true);
      const idsAcoesParaRemover = data.filter(d => !d.seguiraParaWorkflow).map(d => d.id);
      const idsAcoesParaAdicionar = data.filter(d => d.seguiraParaWorkflow).map(d => d.id);
      await api.put('/workflow/ajuizamento/update-seguira-workflow', {
        idsAcoesParaRemover,
        idsAcoesParaAdicionar,
      });
      success('Salvo com sucesso');
    } catch (e) {
      failure('Falha ao salvar');
    }
    setLoading(false);
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {loading ? (
        <div style={{ padding: '0.1rem', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <Table
              columns={[
                { key: 'switch', label: '' },
                { key: 'numContrato', label: 'Nº Contrato' },
                { key: 'luc', label: 'LUC' },
                { key: 'nomeFantasia', label: 'Nome Fantasia' },
                { key: 'razaoSocial', label: 'Razão Social' },
                { key: 'saldoAberto', label: 'Saldo Aberto' },
              ]}
              data={renderedData}
            />
          </div>
          <S.BottomActions>
            <Button onClick={() => save()} text="Salvar" />
          </S.BottomActions>
        </>
      )}
    </div>
  );

  return open ? <Modal size="lg" title="Detalhes" onClose={onClose} content={content} /> : null;
};

export default DetailsModal;
