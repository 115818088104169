import React from 'react';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';

interface Props {
  state: [string, React.Dispatch<React.SetStateAction<string>>];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
  onBlur?: () => any;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  type?: string;
}

export function DateTimeInput({
  state: [value, setValue],
  label,
  placeholder,
  disabled = false,
  style = {},
  error,
  onBlur,
  startIcon,
  endIcon,
  type = 'month',
}: Props): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
      {label && <label style={{ margin: 0, color: '#6D6D6D', fontSize: 14, fontWeight: 600 }}>{label}</label>}
      <TextField
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        onBlur={onBlur}
        size="small"
        type={type}
        error={error}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          style: {
            ...style,
          },
          startAdornment: startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null,
          endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
        }}
      />
    </div>
  );
}
