import styled from 'styled-components/macro';
import bgLogin from '../../assets/bg_login.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ContainerInput = styled.form`
  min-height: 33rem;
`;

export const Content = styled.div`
  background-image: url(${bgLogin});

  display: flex;
  align-items: center;
  padding: 10rem;
  width: 120rem;
  border: 1px solid #00000000;
  border-radius: 20px;
  background-size: cover;
  height: 67rem;
`;

export const FormLogin = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 4rem 9rem;
  max-width: 57rem;
  h1 {
    letter-spacing: 0px;
    color: #495e84;
    opacity: 1;

    font-weight: 600;
    margin-bottom: 2rem;
  }
  label {
    p {
      letter-spacing: 0px;
      color: #495e84;
      opacity: 1;
    }
    input {
      background: #495e8426 0% 0% no-repeat padding-box;
      border-radius: 20px;
      opacity: 1;
      border: 0;
      width: 100%;
      padding: 1rem 2rem;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
  button {
    background: #ff5b22 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border: 0;
    padding: 1rem 6.5rem;
    color: #ffffff;
    opacity: 1;

    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    min-width: unset;
    max-width: unset;
    text-transform: unset;
    margin-top: 4rem;
  }
`;

export const TypoLogo = styled.img`
  width: 144px;
  position: absolute;
  bottom: 32px;
  left: 32px;
`;

export const TopLogoImg = styled.img`
  width: 150px;
`;
export const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const MiddleWrapper = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 112px;
`;
export const BottomWrapper = styled.div`
  text-align: right;
`;
export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  color: #15c170;
`;
export const SubTitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: #3f3f3f;
`;
export const LinkText = styled.p`
  font-size: 14px;
  color: #3f3f3f;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`;
