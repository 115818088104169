import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import * as S from '../styles';
import Modal from './modal';
import api from '../../../../_core/api';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, PaginatedTable } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import { DebtType } from '../tipo-divida';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';

export type DividaConfig = {
  idAjzTipoContrato: number;
  idAjzTipoDivida: number;
  idAjzTipoDocumento: number[];
  idAjzTipoMovimento: number[];
};

export type Documento = {
  idAjzTipoDocumento: number;
  codDocumento: string;
  prefixo: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  updatedAt: Date;
  flagAtivo: boolean;
};

export type Contrato = {
  idAjzTipoContrato: number;
  codContrato: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  updatedAt: Date;
  flagAtivo: boolean;
};

export type Movimento = {
  idTipoMovimento: number;
  codMovimento: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  updatedAt: Date;
  flagAtivo: boolean;
};

const TipoDividaConfig: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<DividaConfig[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [tipoDivida, setTipoDivida] = useState<DebtType[]>([]);
  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [contratos, setContratos] = useState<Contrato[]>([]);
  const [movimentos, setMovimentos] = useState<Movimento[]>([]);
  const [itemInAction, setItemInAction] = useState<DividaConfig | null>();
  const [deletionSelected, setDeletionSelected] = useState<DividaConfig | null>();

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<DividaConfig[]>(`/setup/rules/tipo-divida-config`);
      const { data: dataTipoDivida } = await api.get<DebtType[]>('/setup/rules/tipo-divida');
      const { data: dataContratos } = await api.get<Contrato[]>('/setup/rules/contratos');
      const { data: dataDocs } = await api.get<Documento[]>('/setup/rules/documentos');
      const { data: dataMovimentos } = await api.get<Movimento[]>('/setup/rules/movimentos');

      // setup/rules/movimentos

      setTipoDivida(dataTipoDivida);
      setDocumentos(dataDocs);
      setContratos(dataContratos);
      setMovimentos(dataMovimentos);
      setRegisterList(data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const excludeItem = async () => {
    console.log({ deletionSelected });
    try {
      await api({ method: 'DELETE', url: '/setup/rules/tipo-divida-config', data: deletionSelected });

      success('O registro foi removido');
      setDeletionSelected(null);
      getData();
    } catch (e) {
      handleErrors(e);
      setDeletionSelected(null);
    }
  };

  const getDocsMovsNames = (item: DividaConfig) => {
    const tipoDocsArr = item.idAjzTipoDocumento.map(i => documentos.find(d => d.idAjzTipoDocumento === i));

    const tipoMovsArr = item.idAjzTipoMovimento.map(i => movimentos.find(d => d.idTipoMovimento === i));

    return {
      tipoDocs: tipoDocsArr.map(i => i?.codDocumento).join(', '),
      tipoMovs: tipoMovsArr.map(i => i?.codMovimento).join(', '),
    };
  };

  const renderedData = useMemo(() => {
    if (registerList.length > 0) {
      return registerList.map(item => ({
        ...item,
        tipoDivida: tipoDivida.find(d => d.idAjzTipoDivida === item.idAjzTipoDivida)?.codTipoDivida,
        documento: getDocsMovsNames(item).tipoDocs,
        contrato: contratos.find(d => d.idAjzTipoContrato === item.idAjzTipoContrato)?.codContrato,
        movimento: getDocsMovsNames(item).tipoMovs,
        actions: (
          <S.Actions>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              component="span"
              id="item.idTipoDivida.toString()"
              onClick={() => setItemInAction(item)}
            >
              <MoreVert />
              <S.IconLabel>Editar</S.IconLabel>
            </IconButton>

            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              component="span"
              id="item.idTipoDivida.toString()"
              onClick={() => setDeletionSelected(item)}
            >
              <DeleteIcon />
              <S.IconLabel>Remover</S.IconLabel>
            </IconButton>
          </S.Actions>
        ),
      }));
    }
    return [];
  }, [registerList]);

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      <ConfirmationModal
        open={Boolean(deletionSelected)}
        text="Deseja excluir este item ?"
        handleCancel={() => setDeletionSelected(null)}
        handleOk={excludeItem}
      />
      {itemInAction !== undefined && (
        <Modal
          contratos={contratos}
          documentos={documentos}
          movimentos={movimentos}
          tipoDivida={tipoDivida}
          selectedRegister={itemInAction}
          onClose={() => setItemInAction(undefined)}
          refresh={getData}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField />
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setItemInAction(null)} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          subItemSpan={2}
          columns={[
            { label: 'Tipo Dívida', key: 'tipoDivida' },
            { label: 'Tipo Contrato', key: 'contrato' },
            { label: 'Tipo Documento', key: 'documento' },
            { label: 'Tipo Movimento', key: 'movimento' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.DataContainer>
  );
};

export default TipoDividaConfig;
