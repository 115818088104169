import React, { useCallback, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import { useMsal } from '@azure/msal-react';
import bgLogin from '../../assets/vector-login.png';
import { AuthContext } from '../../_main/contexts/auth';
import { authenticate, getUserAccess } from './request';
import { homeRoute } from './home-route';
import { loginRequest } from '../../_core/config/authConfig';
import { handleErrors } from '../../_core/services/toast';
import { Input } from '../../_core/_components';
import { BottomWrapper, LinkText, MiddleWrapper, SubTitle, Title, TopLogoImg, TopWrapper, TypoLogo } from './styles';
import AllosTypo from '../../assets/allos-typo.svg';
import LogoTop from '../../assets/logo-top.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background: `url(${bgLogin}), #0D454A `,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 32px',
    height: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: '#00616F',
    width: '120px',
    fontWeight: 700,
    fontSize: '14px',
  },
}));

const LOCALHOST = window.location.href.includes('http://localhost');

export function Autenticacao(): JSX.Element {
  const classes = useStyles();

  const { signIn } = useContext(AuthContext);
  const { instance } = useMsal();

  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState('');

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      try {
        setLoading(true);
        let responseToken = '';
        e.preventDefault();
        if (LOCALHOST) {
          responseToken = token;
        } else {
          const response = await instance.loginPopup(loginRequest);
          responseToken = response.accessToken;
        }
        const data = await authenticate(responseToken);
        const routes = await getUserAccess({ idUsuario: data.id, token: data.token });

        routes.unshift(homeRoute);

        signIn({
          id: data.id,
          token: data.token,
          name: data.name,
          userName: data.username,
          email: data.email,
          profile: data.profile.name,
          shoppingProfileName: data.shoppings[0]?.name,
          shoppingProfileId: data.shoppings[0]?.id.toString(),
          shoppings: data.shoppings ?? null,
          routes,
        });
      } catch (error) {
        handleErrors(error);
        setLoading(false);
      }
    },
    [signIn, token],
  );

  const onClickSupport = () => {
    window.open('https://allos.service-now.com/allos?id=sc_cat_item&sys_id=f6c8620187398690f538edf80cbb35b1', '_blank');
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <div className={classes.image} />

      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <TypoLogo src={AllosTypo} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <TopWrapper>
            <TopLogoImg src={LogoTop} />
          </TopWrapper>
          <MiddleWrapper>
            <Title>Bem-vindo ao Siscob.</Title>
            <SubTitle>Faça seu login para acessar a plataforma.</SubTitle>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              {LOCALHOST && <Input state={[token, setToken]} label="AD Token" />}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<LockOutlinedIcon />}
                disableElevation
                className={classes.submit}
                disabled={loading}
              >
                ACESSAR
              </Button>
            </form>
          </MiddleWrapper>
          <BottomWrapper>
            <LinkText onClick={onClickSupport}>Problemas com seu acesso? Fale com o suporte. </LinkText>
          </BottomWrapper>
        </div>
      </Grid>
    </Grid>
  );
}
