import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, Select, Option, Checkbox, Form, SingleSelect } from '../../../_core/_components';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';
import * as S from './styles';
import { Table } from './table';
import api from '../../../_core/api';
import { handleErrors, warning } from '../../../_core/services/toast';
import { Resumo } from './types';
import { downloadXls } from '../../../_core/services/download';
import { DateTimeInput } from '../../../_core/_components/date-time-input';
import { AuthContext } from '../../../_main/contexts/auth';

export function ResumoInadimplencia(): any {
  const { shoppings: profileShoppings } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [shoppingsOptions, setShoppingsOptions] = useState<any[]>([]);
  const [shopping, setShopping] = useState<Option[]>([]);
  const [monthYear, setMonthYear] = useState(moment().format('YYYY-MM'));
  const [apenasParticipacao, setApenasParticipacao] = useState(false);
  const [visao, setVisao] = useState<Option | undefined>({ value: 'TOTAL', label: 'Inadimplência total' });
  const [resumo, setResumo] = useState<Resumo>([]);
  const [visaoResumo, setVisaoResumo] = useState('');

  useEffect(() => {
    if (_.isArray(profileShoppings)) {
      const optionsSorted = _.sortBy(profileShoppings, ['name'], ['asc']) ?? [];
      const optionsArr = optionsSorted.map(e => ({ value: e.id, label: e.name }));
      setShoppingsOptions(optionsArr);
    }
  }, [profileShoppings]);

  const params = useMemo(
    () => ({
      shoppingIds: shopping.map(e => e.value).join(','),
      mes: monthYear.split('-')[1],
      ano: monthYear.split('-')[0],
      apenasParticipacao,
      visao: visao?.value,
    }),
    [shopping, monthYear, visao, apenasParticipacao],
  );

  const getResumo = async () => {
    setIsLoading(true);
    try {
      setResumo([]);
      setVisaoResumo('');
      const { data } = await api.get<Resumo>('/inadimplencia/resumo', { params });
      if (_.isEmpty(data)) {
        warning('Não obteve resultados.');
      } else {
        setResumo(data);
        setVisaoResumo(visao!.label);
      }
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const onSelectRelatorio = async (option: Option) => {
    if (option.value === 'Luc a Luc') {
      await getLucLuc();
      return;
    }
    await gerarResumo();
  };

  const getLucLuc = async () => {
    setIsLoading(true);
    try {
      const { shoppingIds, mes, ano } = params;
      const response = await api.get('/inadimplencia/luc-luc', {
        params: { shoppingIds, mes, ano },
        responseType: 'arraybuffer',
      });
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const gerarResumo = async () => {
    setIsLoading(true);
    try {
      const { shoppingIds, mes, ano } = params;
      const response = await api.get('/inadimplencia/gerar-resumo', {
        params: { shoppingIds, mes, ano },
        responseType: 'arraybuffer',
      });
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  return (
    <S.Container>
      <FullScreenLoading isEnabled={isLoading} />
      <Form
        columns={3}
        items={[
          <Select placeholder="Shoppings" options={shoppingsOptions} state={[shopping, setShopping]} />,
          <DateTimeInput placeholder="MM/AAAA" label="Mês/ano de início" state={[monthYear, setMonthYear]} />,
          <SingleSelect
            state={[visao, setVisao]}
            options={[
              { value: 'TOTAL', label: 'Inadimplência total' },
              { value: 'CORPORATIVO', label: 'Negociação corporativa' },
              { value: 'JURIDICO', label: 'Jurídico' },
            ]}
            placeholder="Visões resumo"
          />,
          <Checkbox
            checked={apenasParticipacao}
            onClick={() => setApenasParticipacao(!apenasParticipacao)}
            label="Apenas participação"
          />,
        ]}
        submitButton={
          <Button text="Pesquisar" onClick={getResumo} disabled={shopping.length === 0 || monthYear.length < 6} />
        }
      />
      {resumo.length > 0 && (
        <>
          <Table data={resumo} visaoResumo={visaoResumo} />
          <S.Footer>
            <S.FooterText>Gerar relatório</S.FooterText>
            <SingleSelect
              state={[undefined, () => null]}
              options={[
                { value: 'Luc a Luc', label: 'Luc a Luc' },
                { value: 'Gerar resumo', label: 'Gerar resumo' },
              ]}
              callbackSelected={onSelectRelatorio}
            />
          </S.Footer>
        </>
      )}
    </S.Container>
  );
}
