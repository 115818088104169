import styled from 'styled-components';

export const Container = styled.div``;

export const MainFormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const CardForm = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  flex: 0.3;
  flex-direction: column;
  padding: 0.6rem;
  gap: 1rem;
`;

export const CardFormTitle = styled.label`
  font-weight: bold;
  color: black;
  font-size: 15px;
  margin-top: 0.6rem;
`;

export const CardFormContentList = styled.div``;

export const CardFormContent = styled.div`
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  padding: 1rem;
  border: 2px dashed #ccc;
  cursor: pointer;
`;

export const TableContainer = styled.table`
  width: 100%;
  background: white;
  margin-top: 1rem;
  padding: 0.6rem;
  border-radius: 0.6rem;
  border-collapse: collapse;

  thead {
    color: white;
  }

  th,
  td {
    border: 1px solid whitesmoke;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #008c90;
    margin: 0;
  }
  th[colspan] {
    text-align: center;
  }
`;

export const FooterText = styled.span`
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 600;
`;

export const Footer = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
`;
