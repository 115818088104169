import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #00616f;
  padding: 16px 24px;
  margin-top: 64px;
  color: #15c170;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
`;

export const BreadCrumb = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
