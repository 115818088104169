import React, { useEffect, useMemo, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BoletosTitulos from './BoletosTitulos';

import * as S from './styles';
import { AcompanhamentoAjuizamentoItem, AjuizamentoDetalhes, AjuizamentoResponse } from '../types';
import ControleDeAcoes, { ControleDeAcoesData } from './ControleDeAcoes';
import StatusProcesso from './StatusProcesso';
import api from '../../../../_core/api';
import { handleErrors, success } from '../../../../_core/services/toast';
import { convertGuiasPagamentoMask, convertToDatabaseDate, GuiaPagamentoMask } from './helpers';
import { converterParaFloat } from '../../../../_core/masks';
import { toReal } from '../../../../_core/services/formaters';
import { SingleSelect, Option } from '../../../../_core/_components';
import { Boleto } from './BoletosTitulos/types';

type Props = {
  onCloseDetalhamento: () => void;
  ajuizamento: AjuizamentoDetalhes;
  onChangeClient: (ajuizamento: AcompanhamentoAjuizamentoItem) => void;
  ajuizamentos: AjuizamentoResponse;
};

const Detalhamento: React.FC<Props> = ({ onCloseDetalhamento, ajuizamento, onChangeClient, ajuizamentos }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [controleDeAcoes, setControleDeAcoes] = useState<ControleDeAcoesData>();
  const [guiasPagamento, setGuiasPagamento] = useState<GuiaPagamentoMask[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedLojista, setSelectedLojista] = useState<Option>();
  const [boletos, setBoletos] = useState<Boleto[]>();

  useEffect(() => {
    setGuiasPagamento(getGuias());

    setSelectedLojista({
      value: ajuizamento.idAcaoAjuizamento,
      label: ajuizamento.razaoSocial,
    });
  }, [ajuizamento]);

  const lojistas: Option[] = ajuizamentos.data.map(a => ({
    value: a.id,
    label: a.razaoSocial,
  }));

  const getGuiasPayload = () => {
    if (guiasPagamento.length > 0) {
      return guiasPagamento;
    }
    return convertGuiasPagamentoMask(ajuizamento.guias);
  };

  const getGuias = () => {
    if (ajuizamento.guias.length > 0) {
      return convertGuiasPagamentoMask(ajuizamento.guias);
    }
    return [];
  };

  const isGuiaPreenchida = (guia: GuiaPagamentoMask) => {
    return (
      guia.comprovantePagamento &&
      guia.valor &&
      guia.numeroPO &&
      guia.numeroChamadoPagamento &&
      guia.numeroChamadoAjuizamento &&
      guia.statusChamadoAjuizamento &&
      guia.numeroProtocolo &&
      guia.dataAjuizamento
    );
  };

  const desabilitarStatusAcaoTipoCredit = useMemo(() => {
    if (guiasPagamento.length === 0) return true;

    return !guiasPagamento.some(isGuiaPreenchida);
  }, [guiasPagamento]);

  const salvarAjuizamentoDetalhes = async () => {
    setLoading(true);

    const payload = {
      guias: getGuiasPayload().map(e => ({
        id: e.id,
        valor: e.valor ? converterParaFloat(e.valor) : null,
        numeroPO: e.numeroPO || null,
        numeroChamadoPagamento: e.numeroChamadoPagamento || null,
        comprovantePagamento: e.comprovantePagamento,
        numeroChamadoAjuizamento: e.numeroChamadoAjuizamento || null,
        statusChamadoAjuizamento: e.statusChamadoAjuizamento || null,
        numeroProtocolo: e.numeroProtocolo || null,
        dataAjuizamento: e.dataAjuizamento ? convertToDatabaseDate(e.dataAjuizamento) : null,
      })),
      idStatusAcao: controleDeAcoes?.statusAcao?.value || ajuizamento.idStatusAcao || null,
      idEspecieCredito: controleDeAcoes?.tipoCredito?.value || ajuizamento.idEspecieCredito || null,
      idFase: ajuizamento.idFaseAtual,
    };
    try {
      const idAcao = ajuizamento?.idAcaoAjuizamento;
      await api.put(`/workflow/ajuizamento/acompanhamento/${idAcao}`, payload);
      onChangeClient(ajuizamentos.data.find(a => a.id === ajuizamento.idAcaoAjuizamento)!);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleChangeClient = (opt: Option) => {
    setCurrentTab(1);
    const selectedAjuItem = ajuizamentos.data.find(a => a.id === opt.value);
    if (selectedAjuItem) {
      onChangeClient(selectedAjuItem);
    }
  };

  const handleAjuUpdateReq = (aju: AjuizamentoDetalhes) => {
    const selectedAjuItem = ajuizamentos.data.find(a => a.id === aju.idAcaoAjuizamento);
    if (selectedAjuItem) {
      onChangeClient(selectedAjuItem);
    }
  };

  return (
    <S.Content>
      <S.TopActions>
        <S.Back onClick={onCloseDetalhamento}>
          <ArrowBackIcon />
          Retornar para lista inicial
        </S.Back>
        <S.ClientChanger>
          <S.SelectLabel>Lojista</S.SelectLabel>
          <SingleSelect
            controlStyle={{ minWidth: '260px' }}
            options={lojistas}
            state={[selectedLojista, setSelectedLojista]}
            callbackSelected={opt => handleChangeClient(opt)}
          />
        </S.ClientChanger>
      </S.TopActions>
      <S.Header>
        <S.HeaderLeft>
          <S.HeaderInfo>
            <S.HeaderInfoLabel>Razão Social</S.HeaderInfoLabel>
            <S.HeaderInfoValue>{ajuizamento.razaoSocial}</S.HeaderInfoValue>
          </S.HeaderInfo>
          <S.HeaderInfo>
            <S.HeaderInfoLabel>Saldo devedor corrigido</S.HeaderInfoLabel>
            <S.HeaderIndicatorValue textColor="red">
              {ajuizamento.saldoVencidoAtualizado ? toReal(ajuizamento.saldoVencidoAtualizado) : '-'}
            </S.HeaderIndicatorValue>
          </S.HeaderInfo>
        </S.HeaderLeft>
        <S.HeaderRight>
          <S.HeaderRightLine>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>Origem</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue>{ajuizamento.origem}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>Shopping</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue>{ajuizamento.nomeShopping}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
          </S.HeaderRightLine>

          <S.HeaderRightLine>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>Nome Fantasia</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue>{ajuizamento.nomeFantasia}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>CPF/CNPJ</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue>{ajuizamento.cpfCnpj}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
          </S.HeaderRightLine>
        </S.HeaderRight>
      </S.Header>

      <S.TabsWrapper>
        <S.Tabs>
          <S.Tab onClick={() => setCurrentTab(1)} active={currentTab === 1}>
            Boletos e títulos
          </S.Tab>
          <S.Tab active={currentTab === 2} onClick={() => setCurrentTab(2)}>
            Controle de ações
          </S.Tab>
          <S.Tab active={currentTab === 3} onClick={() => setCurrentTab(3)}>
            Status do processo
          </S.Tab>
        </S.Tabs>
        <S.TabsContent>
          {currentTab === 1 && (
            <BoletosTitulos
              onUpdateAjuizamento={() => handleAjuUpdateReq(ajuizamento)}
              ajuizamentoDetalhes={ajuizamento}
              setBoletos={setBoletos}
            />
          )}
        </S.TabsContent>
        <S.TabsContent>
          {currentTab === 2 && (
            <ControleDeAcoes
              onSave={salvarAjuizamentoDetalhes}
              onChangeControleDeAcoes={setControleDeAcoes}
              ajuizamentoDetalhes={ajuizamento}
              disableSelects={desabilitarStatusAcaoTipoCredit}
            />
          )}
        </S.TabsContent>
        <S.TabsContent>
          {currentTab === 3 && (
            <StatusProcesso
              onSave={salvarAjuizamentoDetalhes}
              onUpdateGuias={setGuiasPagamento}
              ajuizamento={ajuizamento}
              guiasPagamento={guiasPagamento}
              onRequestUpdateAjuizamento={handleAjuUpdateReq}
              boletos={boletos}
            />
          )}
        </S.TabsContent>
      </S.TabsWrapper>
    </S.Content>
  );
};

export default Detalhamento;
