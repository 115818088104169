import React from 'react';
import { Checkbox, Form, Input, Select } from '../../../../_core/_components';
import { EditProfileProperty } from './reducer';

type Props = {
  form: any;
  dispatch: React.Dispatch<{
    type: EditProfileProperty;
    payload: any;
  }>;
  onEditPermissions: (payload: any) => void;
};

const ModalContent: React.FC<Props> = ({ form, dispatch, onEditPermissions }: Props) => {
  return (
    <Form
      columns={12}
      items={[
        <Input
          state={[
            form.nome ?? '',
            (value: React.SetStateAction<string>) => dispatch({ type: 'PERFIL', payload: value }),
          ]}
          pattern={value => value.toUpperCase()}
          label="Perfil"
        />,
        <Input
          state={[
            form.codPerfil ?? '',
            (value: React.SetStateAction<string>) => dispatch({ type: 'COD_PERFIL', payload: value }),
          ]}
          pattern={value =>
            value
              .replace(/[^\w_]/g, '')
              .toUpperCase()
              .trim()
          }
          label="Código do perfil"
        />,
        <Select
          options={form.todasPermissoes}
          state={[form.permissoes, onEditPermissions]}
          placeholder="Permissão"
          disabled={!form?.idPerfil}
        />,
        <Checkbox
          checked={form.flagAtivo ?? false}
          onClick={() => dispatch({ type: 'ACTIVE', payload: !form.flagAtivo })}
          label="Ativo"
        />,
      ]}
    />
  );
};

export default ModalContent;
