let path = 'https://bff-siscob-dev.integracao.brmalls.com.br';
const { origin } = window.location;
let clientId = '';
let redirectUri = '';

if (process.env.REACT_APP_BFF_URL) {
  path = process.env.REACT_APP_BFF_URL;
}

if (origin.includes('siscob-dev.integracao.brmalls.com.br')) {
  clientId = '332145ce-7ee8-4bd1-8c86-2f49e5698de4';
  redirectUri = 'https://siscob-dev.integracao.brmalls.com.br/';
} else if (origin.includes('siscob-hml.integracao.brmalls.com.br')) {
  clientId = '4cc23c3e-2a67-4d0b-9582-e8337214b18b';
  redirectUri = 'https://siscob-hml.integracao.brmalls.com.br/';
} else if (origin.includes('siscob.integracao.brmalls.com.br')) {
  clientId = '7623ba0a-6a4b-4d63-b2a8-711d8bc60ae9';
  redirectUri = 'https://siscob.integracao.brmalls.com.br/';
} else if (origin.includes('siscob-dev.allos.co')) {
  clientId = '332145ce-7ee8-4bd1-8c86-2f49e5698de4';
  redirectUri = 'https://siscob-dev.allos.co/';
} else if (origin.includes('siscob-hml.allos.co')) {
  clientId = '4cc23c3e-2a67-4d0b-9582-e8337214b18b';
  redirectUri = 'https://siscob-hml.allos.co/';
} else {
  clientId = '7623ba0a-6a4b-4d63-b2a8-711d8bc60ae9';
  redirectUri = 'https://siscob.allos.co/';
}

export default {
  BASE_PATH: `${path}`,
  CLIENT_ID: clientId,
  REDIRECT_URI: redirectUri,
};
