import { CSSObject } from 'react-pro-sidebar';
import styled, { css } from 'styled-components';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import HeaderImg from '../../assets/headerback.png';

type SubMenuTextProps = {
  active?: boolean;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white',
      backgroundImage: `url(${HeaderImg})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '20% 0%',
    },
    avatarContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    avatarInfoContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '0px 1rem',
      color: 'rgb(126, 147, 149)',
      fontSize: '0.8rem',
    },
    popoverPerfil: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1.5rem 2.5rem',
      fontSize: '0.8rem',
      color: 'rgb(126, 147, 149)',
    },
    popoverPerfilUsername: {
      fontSize: '0.8rem',
      fontWeight: 600,
    },
    popoverPerfilButton: {
      background: 'rgb(126, 147, 149) none no-repeat scroll 0% 0% padding-box',
      borderRadius: '1.5rem',
      opacity: 1,
      border: '0rem none',
      color: 'rgb(255, 255, 255)',
      padding: '0.5rem 1.5rem',
      marginTop: '1rem',
      width: '100%',
    },
    content: {
      flexGrow: 1,
      padding: 0,
      minHeight: '100vh',
      marginLeft: '48px',
    },
    menuButton: {
      marginRight: 36,
      color: '#00959c',
    },
  }),
);

export const IconStyle = {
  color: '#00959c',
  fontSize: 14,
  marginRight: 22,
};

export const ArrowStyle = {
  color: '#bababa',
  fontSize: 16,
};

export const MenuItem = styled.div`
  padding: 1rem 1.8rem;
  color: #00959c;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuText = styled.div`
  flex: 1;
`;

export const SubMenuItem = styled.div`
  padding: 0.6rem 1.8rem;
  color: #8890b5;
  font-size: 0.6rem;
  cursor: pointer;
`;

export const SubMenuText = styled.div<SubMenuTextProps>`
  font-size: 0.9rem;
  margin-left: 2.2rem;

  ${({ active }) => css`
    ${active && {
      color: '#7ED6DA',
    }}
  `}
`;

export const Body = styled.div`
  padding: 1rem;
  background-color: white;
  /* border-radius: 4px; */
  /* margin: 0px 8px 8px 8px; */
  /* top: -0.5rem; */
  position: relative;
`;

export const SidebarStyles: CSSObject = {
  backgroundColor: '#FFFFFF',
  zIndex: 1202,
  position: 'fixed',
  top: 0,
  bottom: 0,
  width: '300px',
  minWidth: '300px',

  '&.ps-collapsed': {
    width: '48px',
    minWidth: '48px',

    '.ps-menu-button.ps-active': {
      backgroundColor: 'unset',

      ':hover': {
        backgroundColor: 'unset !important',
      },
    },

    '.ps-menu-button': {
      ':hover': {
        backgroundColor: 'unset !important',
      },
    },

    '.ps-menu-icon:not(.ps-active)': {
      transition: 'all .5s ease',

      ':hover': {
        backgroundColor: '#DAEFE5',
      },
    },
  },
};

export const SubMenuItemStyles: CSSObject = {
  color: '#00616F',
  fontWeight: 700,
  fontSize: '14px',
  margin: '0 4px',
  width: 'unset',

  '.ps-sidebar-container': {
    backgroundColor: '#fff',
  },

  '.downArrow': {
    width: '8px !important',
    margin: '0 18px',
    fill: '#00616F',
  },

  '.ps-menu-button': {
    paddingRight: '0 !important',
    paddingLeft: '0px !important',
    borderRadius: '4px',
    backgroundColor: '#fff',
    height: '40px !important',

    ':hover': {
      backgroundColor: '#fff !important',
    },
  },

  '> .ps-menu-button': {
    ':hover': {
      backgroundColor: '#DAEFE5 !important',
    },
  },

  '.ps-menu-button.ps-active': {
    backgroundColor: '#00616F',
    color: '#15C170',

    '.downArrow': {
      fill: '#15C170',
      transform: 'rotate(180deg)',
    },

    ':hover': {
      backgroundColor: '#00616F !important',
    },
  },

  '.ps-menu-icon': {
    width: '40px',
    minWidth: '40px',
    marginRight: 0,

    svg: {
      width: '16px',
      height: '16px',
    },
  },

  '.ps-menu-icon.ps-active': {
    backgroundColor: '#00616F',
    borderRadius: '4px',
    height: '40px',
    svg: {
      path: {
        fill: '#15C170',
      },
    },
  },
};

// export const SubMenuItemStyles2: CSSObject = {};

export const MenuItemStyles: CSSObject = {
  fontSize: '14px',
  color: '#3F3F3F',
  fontWeight: 400,
  paddingLeft: '40px',

  '.ps-menu-button': {
    height: '30px!important',
  },

  '.ps-menu-label:hover': {
    color: '#15C170',
  },
};

export const IconImg = styled.img`
  width: 16px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 12px;
`;
export const Logo = styled.img`
  width: 32px;
  height: 32px;
`;

export const LogoBig = styled.img`
  width: 120px;
  height: 29px;
`;

export const UserName = styled.p`
  color: #3f3f3f;
  font-size: 12px;
  font-weight: 700;
`;
export const UserRole = styled.p`
  color: #3f3f3f;
  font-size: 12px;
  font-weight: 400;
`;
