import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination, PaginationItem } from '@material-ui/lab';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableSortLabel, Tooltip, useMediaQuery } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { PriorizationItem, ShoppingEntity } from '../types';

import { toReal } from '../../../../_core/services/formaters';
import { maskCPFCNPJ } from '../../../../_core/masks';
import { PaginationWrapper } from '../../../../_core/_components/table/styles';

const useCellStyles = makeStyles(() => ({
  head: (props: { isSmallScreen: boolean }) => ({
    backgroundColor: '#fff',
    color: '#008C90',
    fontWeight: 'bold',
    border: '1px solid #E5E9EA',
    fontSize: props.isSmallScreen ? '1em' : '1.2em',
    padding: props.isSmallScreen ? '0 0.5em' : '0 1.2em',
    height: '48px',
    whiteSpace: 'nowrap',
  }),
  body: (props: { isSmallScreen: boolean }) => ({
    fontSize: props.isSmallScreen ? '1em' : '1.2em',
    padding: props.isSmallScreen ? '0 0.5em' : '0 1.2em',
    height: '40px',
  }),
  stickyHeader: {
    top: '5.4em',
  },
}));

const StyledTableCell = (props: any) => {
  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const classes = useCellStyles({ isSmallScreen });

  return <TableCell classes={classes} {...props} />;
};

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',
      cursor: 'pointer',
      '&:nth-of-type(odd)': {
        backgroundColor: '#E5E9EA',
      },

      '&:hover': {
        backgroundColor: '#daeedd',
      },
    },
  }),
)(TableRow);

const StyledCheckIcon = withStyles(() =>
  createStyles({
    root: {
      color: '#008C90',
    },
  }),
)(CheckCircleIcon);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    fontSize: '10px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

type Props = {
  priorizationList?: PriorizationItem[];
  selectedDate: Date;
  shopping: ShoppingEntity;
};

interface HeadCell {
  disablePadding: boolean;
  id: HeadKeys;
  label: string;
  numeric: boolean;
}

type HeadKeys =
  | 'priority'
  | 'isBillingGroup'
  | 'companyName'
  | 'documentNumber'
  | 'overdueThisMonth'
  | 'overdue120Days'
  | 'totalOverdue'
  | 'amountDue'
  | 'status'
  | 'dueDate';

const headCells: HeadCell[] = [
  { id: 'priority', numeric: true, disablePadding: true, label: 'Prioridade' },
  { id: 'isBillingGroup', numeric: false, disablePadding: false, label: 'Corporativo' },
  { id: 'companyName', numeric: false, disablePadding: false, label: 'Razão Social' },
  { id: 'documentNumber', numeric: false, disablePadding: false, label: 'CPF/CNPJ' },
  { id: 'amountDue', numeric: true, disablePadding: false, label: 'Mês à vencer' },
  { id: 'overdueThisMonth', numeric: true, disablePadding: false, label: 'Mês atual' },
  { id: 'overdue120Days', numeric: true, disablePadding: false, label: 'Total 120 dias' },
  { id: 'totalOverdue', numeric: true, disablePadding: false, label: 'Total geral' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status Negociação' },
  { id: 'dueDate', numeric: false, disablePadding: false, label: 'Data Contato' },
];

type Order = 'asc' | 'desc';

const CustomizedTables: React.FC<Props> = ({ priorizationList, selectedDate, shopping }) => {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState<HeadKeys>('priority');
  const [order, setOrder] = useState<Order>('asc');

  const createSortHandler = (property: HeadKeys) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const orderComparator = comparator(a[0], b[0]);
      if (orderComparator !== 0) return orderComparator;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderByComparator: keyof T) {
    if (b[orderByComparator] < a[orderByComparator]) {
      return -1;
    }
    if (b[orderByComparator] > a[orderByComparator]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    orderVar: Order,
    orderByVar: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return orderVar === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByVar)
      : (a, b) => -descendingComparator(a, b, orderByVar);
  }

  const TitleTooltip = React.forwardRef(function TitleTooltip(props: { title: string }, ref: any) {
    return (
      <div {...props} ref={ref}>
        {props.title}
      </div>
    );
  });

  const paginatedData = useMemo(() => {
    const sortedData = stableSort(priorizationList!, getComparator(order, orderBy));
    const startIndex = (page - 1) * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  }, [priorizationList, page, rowsPerPage, order, orderBy]);

  if (priorizationList && priorizationList.length > 0) {
    return (
      <>
        <Table stickyHeader className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan={4} />
              <StyledTableCell align="center" colSpan={4}>
                Saldos
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={2} />
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {headCells.map(c => (
                <StyledTableCell sortDirection={orderBy === c.id ? order : false} key={c.id}>
                  <TableSortLabel
                    active={orderBy === c.id}
                    direction={orderBy === c.id ? order : 'asc'}
                    onClick={createSortHandler(c.id)}
                  >
                    {c.label}
                    {orderBy === c.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map(row => (
              <StyledTableRow
                onClick={() => history.push('/boletos', { ...row, selectedDate, shopping, fullData: priorizationList })}
                key={row.priority}
              >
                <StyledTableCell component="th" scope="row">
                  {row.priority}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.isBillingGroup && <StyledCheckIcon fontSize="small" />}
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title={row.companyName}>
                    <TitleTooltip title={row.companyName} />
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>{maskCPFCNPJ(row.documentNumber)}</StyledTableCell>
                <StyledTableCell>
                  {row.amountDue.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </StyledTableCell>
                <StyledTableCell>
                  {row.overdueThisMonth.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </StyledTableCell>
                <StyledTableCell>
                  {row.overdue120Days.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </StyledTableCell>
                <StyledTableCell>
                  {row.totalOverdue.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.updateDate ? moment(row.updateDate).format('DD/MM/YYYY') : '-'}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationWrapper>
          <Pagination
            showFirstButton
            showLastButton
            count={Math.ceil(priorizationList.length / 20)}
            page={page}
            renderItem={item => (
              <PaginationItem
                {...item}
                onClick={() => {
                  if (item.type === 'last') {
                    setPage(prevPage => Math.min(prevPage + 10, Math.ceil(priorizationList.length / 20))); // Avança 10 páginas, respeitando o máximo
                  } else if (item.type === 'first') {
                    setPage(prevPage => Math.max(prevPage - 10, 1)); // Volta 10 páginas, respeitando o mínimo
                  } else {
                    setPage(item.page);
                  }
                }}
              />
            )}
          />
        </PaginationWrapper>
      </>
    );
  }

  return <></>;
};

export default CustomizedTables;
