import React from 'react';

const CobrancaIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4841_10874)">
        <path
          d="M10.0003 2.05933C5.40033 2.05933 1.66699 5.79266 1.66699 10.3927C1.66699 14.9927 5.40033 18.726 10.0003 18.726C14.6003 18.726 18.3337 14.9927 18.3337 10.3927C18.3337 5.79266 14.6003 2.05933 10.0003 2.05933ZM10.0003 17.0593C6.32533 17.0593 3.33366 14.0677 3.33366 10.3927C3.33366 6.71766 6.32533 3.72599 10.0003 3.72599C13.6753 3.72599 16.667 6.71766 16.667 10.3927C16.667 14.0677 13.6753 17.0593 10.0003 17.0593ZM10.2587 9.67599C8.78366 9.30099 8.30866 8.89266 8.30866 8.28433C8.30866 7.58433 8.96699 7.09266 10.0587 7.09266C11.2087 7.09266 11.642 7.64266 11.6753 8.45933H13.1003C13.0587 7.34266 12.3753 6.31766 11.0253 5.98433V4.55933H9.08366V5.96766C7.82533 6.23433 6.81699 7.05099 6.81699 8.30933C6.81699 9.80099 8.05866 10.551 9.86699 10.9843C11.492 11.3677 11.817 11.9427 11.817 12.5427C11.817 12.9843 11.492 13.701 10.067 13.701C8.73366 13.701 8.20866 13.101 8.13366 12.3343H6.70033C6.78366 13.751 7.83366 14.551 9.08366 14.8093V16.226H11.0337V14.8343C12.3003 14.5927 13.3003 13.8677 13.3087 12.526C13.3003 10.6927 11.7253 10.0593 10.2587 9.67599Z"
          fill="#00616F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4841_10874">
          <rect width="20" height="20" fill="white" transform="translate(0 0.392578)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CobrancaIcon;
