import React, { useState } from 'react';
import Button from '../button';
import { OptionTypes } from '../..';
import api from '../../../../../_core/api';
import { Checkbox, Form, Input, Modal } from '../../../../../_core/_components';

type Props = {
  onClose: () => void;
  type: OptionTypes | undefined;
  onUpdate: () => void;
};

const DetailsModal: React.FC<Props> = ({ onClose, type, onUpdate }) => {
  const [description, setDescription] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [afetaResumoInadimplencia, setAfetaResumoInadimplencia] = useState(false);

  const getTitle = (param: Props['type']) => {
    switch (param) {
      case 'expecativa':
        return 'EXPECTATIVA DE RECEBIMENTO';
      case 'tipoNeg':
        return 'ESTÁGIOS DE NEGOCIAÇÃO';
      case 'motivoImprovavel':
        return 'MOTIVO IMPROVÁVEL RECEBIMENTO';
      default:
        return '';
    }
  };

  const saveOption = async () => {
    let url = '';
    switch (type) {
      case 'expecativa':
        url = '/api/allos/v1/prioritization/expectation-receipt';
        break;
      case 'tipoNeg':
        url = '/api/allos/v1/prioritization/type-negotiation';
        break;
      case 'motivoImprovavel':
        url = '/api/allos/v1/prioritization/unlikely-reason-receipt';
        break;
      default:
        break;
    }

    setLoading(true);
    if (type === 'expecativa') {
      await api.post(url, { description, active: status, afetaResumoInadimplencia });
    } else {
      await api.post(url, description);
    }

    setDescription('');
    setLoading(false);
    onUpdate();
  };

  const formItemType =
    type === 'expecativa' ? (
      <Checkbox
        checked={afetaResumoInadimplencia}
        onClick={() => setAfetaResumoInadimplencia(!afetaResumoInadimplencia)}
        label="Afeta resumo de inadimplência"
      />
    ) : null;

  const formItemStatus =
    type === 'expecativa' ? <Checkbox label="Status" checked={status} onClick={() => setStatus(!status)} /> : null;

  const content = (
    <Form
      columns={12}
      items={[<Input state={[description, setDescription]} label="Descrição" />, formItemStatus, formItemType]}
    />
  );

  return (
    <Modal
      size="xs"
      title={getTitle(type)}
      onClose={onClose}
      content={content}
      actionItems={[<Button disabled={isLoading} onClick={() => saveOption()} size="small" text="Salvar" />]}
    />
  );
};

export default DetailsModal;
