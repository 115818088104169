import React, { useEffect, useMemo, useState } from 'react';
import { ReportProblem } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { Modal, Table, Switch, Button } from '../../../_core/_components';
import { handleErrors, success } from '../../../_core/services/toast';
import api from '../../../_core/api';
import { BoletoDetalheDivida, TituloDetalheDivida } from './types';
import { convertFromDatabaseDate } from '../../../_core/services/formaters';

export function DetalhamentoDivida({ values, onClose }: { values: any; onClose: () => void }): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<BoletoDetalheDivida[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: dataBoletos } = await api.get(`workflow/ajuizamento/acoes/${values.id}/boletos?size=1000`);
        const arr: BoletoDetalheDivida[] = dataBoletos.data
          ? dataBoletos.data.map((e: any) => ({ ...e, switch: true }))
          : [];
        setData(arr);
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    };
    getData();
  }, []);

  const onClickBoleto = async (index: number) => {
    const boleto = data[index];
    if (boleto.titulos !== undefined) return;
    setLoading(true);
    try {
      const arr = [...data];
      const { data: dataTitulos } = await api.get(
        `workflow/ajuizamento/acoes/${values.id}/boletos/${boleto.idBoleto}/titulos?size=1000`,
      );
      arr[index].titulos = dataTitulos.data.map((e: any) => ({ ...e, switch: e.considerar ?? true }));
      setData(arr);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onSwitchTitulo = (index: number, indexBoleto: number) => {
    const arr = [...data];
    const arrTitulos = [...(arr[indexBoleto].titulos ?? [])];
    arrTitulos[index].switch = !arrTitulos[index].switch;
    setData(arr);
  };

  const renderSubItems = (indexBoleto: number, titulos?: TituloDetalheDivida[]) => {
    if (titulos && titulos.length > 0) {
      return [
        <></>,
        <>
          {titulos.map((e, index) => (
            <div style={{ display: 'flex', marginBottom: '1rem' }} key={e.idTitulo}>
              <Switch state={[e.switch, () => onSwitchTitulo(index, indexBoleto)]} hideLabel />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#028C90' }}>Nº título:</span>
                <p>{e.numeroTitulo}</p>
              </div>
            </div>
          ))}
        </>,
        <>
          {titulos.map(e => (
            <div style={{ marginBottom: '1rem' }} key={e.idTitulo}>
              <span style={{ color: '#028C90' }}>Valor:</span>
              <p>{e.valorVencimentoOriginal?.toLocaleString()}</p>
            </div>
          ))}
        </>,
        <>
          {titulos.map(e => (
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '1rem' }} key={e.idTitulo}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#028C90' }}>Data vencimento:</span>
                <p>{convertFromDatabaseDate(e.dataVencimento)}</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#028C90' }}>Data competência:</span>
                <p>{e.dataCompetencia ? convertFromDatabaseDate(e.dataCompetencia) : '-'}</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#028C90' }}>Tipo movimento:</span>
                <p>{e.tipoMovimento}</p>
              </div>
            </div>
          ))}
        </>,
      ];
    }
    return null;
  };

  const onSwitchBoleto = (index: number) => {
    if (data[index].titulos === undefined) {
      onClickBoleto(index);
      return;
    }
    const arr = [...data];
    arr[index].switch = !arr[index].switch;
    arr[index].titulos = arr[index].titulos?.map(e => ({ ...e, switch: arr[index].switch }));
    setData(arr);
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map((e, index) => ({
        ...e,
        valorTitulosStr: e.saldoTitulos?.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          currency: 'BRL',
          style: 'currency',
        }),
        valorStr: e.valor?.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          currency: 'BRL',
          style: 'currency',
        }),
        dataPtBr: convertFromDatabaseDate(e.dataVencimento),
        switch: <Switch state={[e.switch, () => onSwitchBoleto(index)]} hideLabel />,
        subItems: renderSubItems(index, e.titulos),
      }));
    }
    return [];
  }, [data]);

  const arrTitulos = useMemo(() => {
    const arr: any[] = [];
    data.forEach(e => {
      if (e.titulos) {
        e.titulos.forEach(titulo => {
          arr.push(titulo);
        });
      }
    });
    return arr;
  }, [data]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const payload = arrTitulos.map(e => ({
        idTitulo: e.idTitulo,
        considerar: e.switch,
        juros: e.valJuros,
        multa: e.valMulta,
        correcao: e.valCorrecao,
      }));
      await api.put(`/workflow/ajuizamento/acompanhamento/${values.id}/titulos`, payload);
      success('Alterações salvas');
      onClose();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickRow = async (boleto: BoletoDetalheDivida) => {
    const indexOf = data.findIndex(e => e.idBoleto === boleto.idBoleto);
    await onSwitchBoleto(indexOf);
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <span>
        A lista abaixo exibe dados de dívidas de Shoppings/Grupo de Cobrança selecionados na tela de pesquisa anterior.
      </span>
      {loading ? (
        <div style={{ padding: '0.1rem', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <Table
              columns={[
                { key: 'switch', label: '' },
                { key: 'numeroBoleto', label: 'Nº boleto' },
                { key: 'valorTitulosStr', label: 'Total títulos selecionados' },
                { key: 'valorStr', label: 'Valor Boleto' },
                { key: 'dataPtBr', label: 'Data vencimento' },
              ]}
              data={renderedData}
              onRowClick={onClickRow}
            />
          </div>
          <div
            style={{
              padding: '1rem',
              borderRadius: 5,
              border: '1px solid #F69707',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <ReportProblem style={{ color: '#F69707', marginRight: 8 }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#223354', fontWeight: 700 }}>Desconsiderar título</span>
              <span>
                Desabilitando um título você confirma que ele não será considerado no processo de ajuizamento.
              </span>
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
              <Button text="Cancelar" onClick={onClose} variant="outlined" />
              <Button text="Confirmar" onClick={onSubmit} disabled={arrTitulos.length === 0} />
            </div>
          </div>
        </>
      )}
    </div>
  );

  return <Modal size="lg" title="Detalhes da dívida" onClose={onClose} content={content} />;
}
