import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import api from '../../../../_core/api';
import { handleErrors, success, warning, failure } from '../../../../_core/services/toast';
import { Button, Form, Option, Select, SingleSelect } from '../../../../_core/_components';
import { Contrato, DividaConfig, Documento, Movimento } from '.';

import * as S from '../styles';
import { DebtType } from '../tipo-divida';

type Props = {
  onClose: () => void;
  refresh: () => void;
  selectedRegister: DividaConfig | null;
  tipoDivida: DebtType[];
  contratos: Contrato[];
  documentos: Documento[];
  movimentos: Movimento[];
};

const Modal: React.FC<Props> = ({
  onClose,
  refresh,
  selectedRegister,
  tipoDivida,
  contratos,
  documentos,
  movimentos,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedTipoDivida, setSelectedTipoDivida] = useState<Option>();
  const [selectedContrato, setSelectedContrato] = useState<Option>();
  const [selectedDocumentos, setSelectedDocumentos] = useState<Option[]>([]);
  const [selectedMovimentos, setSelectedMovimentos] = useState<Option[]>([]);

  useEffect(() => {
    if (selectedRegister) {
      const tipoDividaParent = tipoDivida.find(t => t.idAjzTipoDivida === selectedRegister.idAjzTipoDivida);
      setSelectedTipoDivida({
        value: tipoDividaParent!.idAjzTipoDivida,
        label: tipoDividaParent!.codTipoDivida,
      });

      const contratoParent = contratos.find(t => t.idAjzTipoContrato === selectedRegister.idAjzTipoContrato);
      setSelectedContrato({
        value: contratoParent!.idAjzTipoContrato,
        label: contratoParent!.codContrato,
      });

      const documentosSelected = selectedRegister.idAjzTipoDocumento.map(d => ({
        label: documentos.find(i => i.idAjzTipoDocumento === d)!.codDocumento,
        value: documentos.find(i => i.idAjzTipoDocumento === d)!.idAjzTipoDocumento,
      }));
      setSelectedDocumentos(documentosSelected);

      const movimentosSelected = selectedRegister.idAjzTipoMovimento.map(d => ({
        label: movimentos.find(i => i.idTipoMovimento === d)!.codMovimento,
        value: movimentos.find(i => i.idTipoMovimento === d)!.idTipoMovimento,
      }));
      setSelectedMovimentos(movimentosSelected);
    }
  }, [selectedRegister]);

  const tipoDividaOptions = tipoDivida.map(t => ({
    label: t.codTipoDivida,
    value: t.idAjzTipoDivida,
  }));

  const contratoOptions = contratos
    .filter(c => c.flagAtivo)
    .map(t => ({
      label: t.codContrato,
      value: t.idAjzTipoContrato,
    }));

  const documentoOptions = documentos
    .filter(d => d.flagAtivo)
    .map(t => ({
      label: t.codDocumento,
      value: t.idAjzTipoDocumento,
    }));

  const movimentoOptions = movimentos
    .filter(d => d.flagAtivo)
    .map(t => ({
      label: t.codMovimento,
      value: t.idTipoMovimento,
    }));

  const isFormValid = () => {
    // if (selectedContrato && selectedDocumento && selectedTipoDivida && selectedMovimento) {
    //   return true;
    // }

    return true;
  };

  const save = async () => {
    if (!isFormValid()) {
      warning('Preencha os campos do formulário');
      return;
    }
    setLoading(true);
    const payload = {
      idAjzTipoDocumento: selectedDocumentos?.map(d => d.value),
      idAjzTipoMovimento: selectedMovimentos?.map(d => d.value),
      idAjzTipoContrato: selectedContrato?.value,
      idAjzTipoDivida: selectedTipoDivida?.value,
    };
    try {
      if (selectedRegister) {
        await api.put(`/setup/rules/tipo-divida-config`, payload);
      } else {
        await api.post('/setup/rules/tipo-divida-config', payload);
      }
      success('Salvo com sucesso.');
      onClose();
      refresh();
    } catch (e) {
      if ((e as any).response?.data) {
        failure((e as any).response.data);
      } else {
        handleErrors(e);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <S.CloseAction onClick={onClose}>
        <CloseIcon />
      </S.CloseAction>
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <SingleSelect
                state={[selectedTipoDivida, setSelectedTipoDivida]}
                placeholder="Tipo de dívida"
                options={tipoDividaOptions}
                disabled={!!selectedRegister}
              />,
              <SingleSelect
                state={[selectedContrato, setSelectedContrato]}
                placeholder="Tipo de Contrato"
                options={contratoOptions}
                disabled={!!selectedRegister}
              />,
              <Select
                state={[selectedDocumentos, setSelectedDocumentos]}
                placeholder="Tipo de Documento"
                options={documentoOptions}
              />,
              <Select
                state={[selectedMovimentos, setSelectedMovimentos]}
                placeholder="Tipo de Movimento"
                options={movimentoOptions}
              />,
            ]}
            submitButton={<Button startIcon={<SaveIcon />} onClick={save} text="Salvar" disabled={loading} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
