import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { Button, Input, PaginatedTable, Modal } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { CONTATO, ENDERECO, INITIAL_STATE, reducer } from './reducer';
import ModalContent from './modal-content';

export const Shopping: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [nomShopping, setNomShopping] = useState('');
  const [shoppings, setShoppings] = useState<any[]>([]);
  const [form, dispatch] = useReducer(reducer, undefined);

  useEffect(() => {
    getShoppings(1);
  }, []);

  const getShoppings = async (page: number) => {
    setIsLoading(true);
    try {
      const queryNomeShopping = nomShopping.length > 0 ? `&nome=${nomShopping}` : '';
      const { data } = await api.get(`/empresas/shoppings?page=${page}${queryNomeShopping}`);
      setShoppings(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    return shoppings.map(e => ({
      ...e,
      editar: (
        <IconButton
          onClick={() =>
            dispatch({
              type: 'SET_SHOPPING',
              payload: {
                ...e,
                participacao: e.participacao.toString().replace('.', ','),
                contatos: e.contatos?.length > 0 ? e.contatos : [CONTATO],
                endereco: e.endereco ?? ENDERECO,
              },
            })
          }
        >
          <Edit />
        </IconButton>
      ),
    }));
  }, [shoppings, nomShopping]);

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...form,
        participacao: form.participacao?.replace(',', '.'),
        ativo: true,
      };
      if (form.id) {
        await api.put(`/empresas/shoppings/${form.id}`, payload);
      } else {
        await api.post(`/empresas/shoppings`, payload);
      }
      dispatch({ type: 'UNSET_SHOPPING' });
      const { data } = await api.get(`/empresas/shoppings?page=1`);
      setShoppings(data.data);
      setPageInfo(data.pageInfo);
      success('Shopping salva com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {form && (
        <Modal
          title="Adicionar / Alterar Shopping"
          onClose={() => dispatch({ type: 'UNSET_SHOPPING' })}
          content={<ModalContent form={form} dispatch={dispatch} />}
          actionItems={[<Button onClick={onClickSave} text="Salvar" />]}
        />
      )}
      <FullScreenLoading isEnabled={isLoading} />
      <S.DataContainer>
        <S.TopBar>
          <S.SearchBar>
            <S.SearchField>
              <Input
                state={[nomShopping, setNomShopping]}
                label="Buscar pelo shopping"
                onBlur={() => getShoppings(1)}
              />
            </S.SearchField>
          </S.SearchBar>
          <Button
            text="Novo"
            onClick={() => dispatch({ type: 'SET_SHOPPING', payload: INITIAL_STATE })}
            styles={{ width: 100 }}
          />
        </S.TopBar>
        {shoppings.length > 0 && (
          <PaginatedTable
            columns={[
              { label: 'Nome shopping', key: 'nome' },
              { label: 'Data início', key: 'dataInicio' },
              { label: 'Data fim', key: 'dataFim' },
              { label: 'Participação', key: 'participacao' },
              { label: 'Portfólio', key: 'portfolio' },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
            pagination={pageInfo}
            onChangePage={getShoppings}
          />
        )}
      </S.DataContainer>
    </>
  );
};
