import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { Input, PaginatedTable, Modal, Button } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { reducer } from './reducer';
import { useCache } from '../../../../_core/cache';
import { requests } from '../../../../requests';
import ModalContent from './modal-content';

export const UnidadeEconomica: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [nomShopping, setNomShopping] = useState('');
  const [unidadesEconomicas, setUnidadesEconomicas] = useState<{ pageInfo: any; data: any[] }>({
    pageInfo: { currentPage: 1, totalPages: 0, sort: '' },
    data: [],
  });
  const [form, dispatch] = useReducer(reducer, undefined);

  const { response: responseEmpresas, isLoading: isLoadingEmpresas } = useCache({
    key: 'getEmpresas',
    fn: requests.get('getEmpresas'),
  });

  useEffect(() => {
    getUnidadesEconomicas();
  }, [unidadesEconomicas.pageInfo.currentPage, unidadesEconomicas.pageInfo.sort]);

  const getUnidadesEconomicas = async () => {
    setIsLoading(true);
    try {
      const { currentPage, sort } = unidadesEconomicas.pageInfo;
      const { data } = await api.get(`/empresas/unidades-economicas?${sort}&page=${currentPage}`);
      const obj = { ...data };
      obj.pageInfo.sort = sort;
      setUnidadesEconomicas(obj);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    return unidadesEconomicas.data
      .filter(e => e.nome.includes(nomShopping))
      .map(e => ({
        ...e,
        editar: (
          <IconButton
            onClick={() =>
              dispatch({
                type: 'SET_UNIDADE_ECONOMICA',
                payload: {
                  ...e,
                  empresas:
                    e.empresas?.length > 0
                      ? e.empresas
                      : [
                          {
                            codigoUnidadeEconomica: '',
                            empresaId: 0,
                            dtInicioVigencia: '',
                            dtFimVigencia: '',
                            principal: false,
                          },
                        ],
                },
              })
            }
          >
            <Edit />
          </IconButton>
        ),
      }));
  }, [unidadesEconomicas.data, nomShopping]);

  const onClickDelete = async (index: number) => {
    setIsLoading(true);
    try {
      const arr = [...form.empresas];
      const empresa = arr[index];
      if (empresa.id) {
        await api.delete(`/empresas/unidades-economicas/empresa/${empresa.id}`);
      }
      arr.splice(index, 1);
      dispatch({ type: 'SET_EMPRESAS', payload: arr });
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      const payload = form.empresas.map((e: any) => ({
        id: e.id,
        empresaId: e.empresaId,
        codigoUnidadeEconomica: e.codigoUnidadeEconomica,
        shoppingId: form.id,
        origem: form.origem,
        ativo: e.ativo,
        principal: e.principal,
      }));
      await api.put(`/empresas/unidades-economicas/empresas`, payload);
      dispatch({ type: 'UNSET_UNIDADE_ECONOMICA' });
      const { data } = await api.get(`/empresas/unidades-economicas?page=1`);
      setUnidadesEconomicas(data);
      success('Unidade econômica salva com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const isDisabled = () => {
    if (!form) return true;

    const principalArr = form.empresas.map((e: any) => e.principal);
    if (principalArr.filter((e: boolean) => e).length !== 1) return true;

    return false;
  };

  const modalContent = (
    <ModalContent form={form} dispatch={dispatch} onClickDelete={onClickDelete} empresas={responseEmpresas?.data} />
  );

  return (
    <>
      {form && (
        <Modal
          title="Adicionar / Alterar Shopping"
          onClose={() => dispatch({ type: 'UNSET_UNIDADE_ECONOMICA' })}
          content={modalContent}
          actionItems={[<Button onClick={onClickSave} text="Salvar" disabled={isDisabled()} />]}
        />
      )}
      <FullScreenLoading isEnabled={isLoading || isLoadingEmpresas} />
      <S.DataContainer>
        <S.TopBar>
          <S.SearchBar>
            <S.SearchField>
              <Input state={[nomShopping, setNomShopping]} label="Buscar pelo shopping" />
            </S.SearchField>
          </S.SearchBar>
        </S.TopBar>
        {unidadesEconomicas.data.length > 0 && (
          <PaginatedTable
            columns={[
              { label: 'Nome shopping', key: 'nome', orderable: true },
              { label: 'Origem', key: 'origem', orderable: true },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
            pagination={unidadesEconomicas.pageInfo}
            onChangePage={(currentPage, sort) =>
              setUnidadesEconomicas({
                ...unidadesEconomicas,
                pageInfo: { ...unidadesEconomicas.pageInfo, currentPage, sort: sort ?? '' },
              })
            }
          />
        )}
      </S.DataContainer>
    </>
  );
};
