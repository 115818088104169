import React from 'react';

const CartasIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4841_15564)">
        <path
          d="M16.667 3.72583H3.33366C2.41699 3.72583 1.67533 4.47583 1.67533 5.3925L1.66699 15.3925C1.66699 16.3092 2.41699 17.0592 3.33366 17.0592H16.667C17.5837 17.0592 18.3337 16.3092 18.3337 15.3925V5.3925C18.3337 4.47583 17.5837 3.72583 16.667 3.72583ZM16.667 15.3925H3.33366V7.05916L10.0003 11.2258L16.667 7.05916V15.3925ZM10.0003 9.55916L3.33366 5.3925H16.667L10.0003 9.55916Z"
          fill="#00616F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4841_15564">
          <rect width="20" height="20" fill="white" transform="translate(0 0.392578)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CartasIcon;
