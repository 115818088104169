/* eslint-disable dot-notation */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { Avatar, IconButton, Popover } from '@material-ui/core';
import { AuthContext } from '../contexts/auth';
import PageHeader from '../../_core/_components/page-header';
import {
  Body,
  Logo,
  LogoWrapper,
  SubMenuItemStyles,
  SidebarStyles,
  MenuItemStyles,
  useStyles,
  LogoBig,
  UserName,
  UserRole,
} from './styles';
import ShortLogo from '../../assets/siscob-short-logo.svg';
import BigLogo from '../../assets/menu-logo-big.svg';
import DownIcon from '../../assets/downArrow.jsx';
import { Route } from '../routes/_route';

import CobrancaIcon from '../../assets/cobranca-icon';
import SerasaIcon from '../../assets/serasa-icon';
import AjuizamentoIcon from '../../assets/aju-icon';
import ConfigIcon from '../../assets/config-icon';
import InadIcon from '../../assets/inad-icon';
import CartasIcon from '../../assets/cartas-icon';
import HomeIcon from '../../assets/home-icon';

type Props = {
  children: ReactNode;
};

export function Layout({ children }: Props): JSX.Element {
  const { signOut, profile, userName, name, email } = useContext(AuthContext);
  const classes = useStyles();
  const { routes } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDrawer = Boolean(anchorEl);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [stateRoutes, setStateRoutes] = useState<Route[]>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const sortedRoutes = _.sortBy(routes, e => e.nome)
      .filter(r => r.nome)
      .filter(item => item.nome !== 'Boletos');
    const indexConf = sortedRoutes.findIndex(s => s.nome === 'Configurações');
    if (indexConf !== -1) {
      sortedRoutes.push(sortedRoutes.splice(indexConf, 1)[0]);
      setStateRoutes(sortedRoutes);
    }
  }, [routes]);

  const getIcon = (item: Route) => {
    if (item.nome === 'Home') {
      return <HomeIcon />;
    }

    if (item.nome === 'Cobranca') {
      return <CobrancaIcon />;
    }

    if (item.nome === 'Cartas e Email') {
      return <CartasIcon />;
    }

    if (item.nome === 'Cartas e E-mail') {
      return <CartasIcon />;
    }

    if (item.nome === 'Ajuizamento') {
      return <AjuizamentoIcon />;
    }

    if (item.nome === 'Inadimplência') {
      return <InadIcon />;
    }

    if (item.nome === 'Serasa') {
      return <SerasaIcon />;
    }

    if (item.nome === 'Configurações') {
      return <ConfigIcon />;
    }

    return <CobrancaIcon />;
  };

  const onClickRoute = (url: string) => {
    setIsCollapsed(true);
    history.push(url);
  };

  const checkActive = (r: Route) => {
    if (r.menus.find(m => m.url === location.pathname)) return true;

    return false;
  };

  const handleOpenMenu = (route: Route) => {
    setIsCollapsed(false);
    const handledRoutes = stateRoutes?.map(r => {
      if (route.nome === r.nome) {
        return {
          ...r,
          isOpen: !r.isOpen,
        };
      }
      return { ...r, isOpen: false };
    });

    setStateRoutes(handledRoutes);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.avatarContainer}>
            <div className={classes.avatarInfoContainer}>
              <UserName>{name}</UserName>
              <UserRole>{profile}</UserRole>
            </div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
              color="inherit"
            >
              <Avatar />
            </IconButton>
            <Popover onClose={() => setAnchorEl(null)} open={openDrawer} anchorEl={anchorEl}>
              <div className={classes.popoverPerfil}>
                <Avatar style={{ height: 60, width: 60 }} />
                <span className={classes.popoverPerfilUsername}>{userName}</span>
                <p>{profile}</p>
                <p>{email}</p>
                <button className={classes.popoverPerfilButton} type="button" onClick={signOut}>
                  Sair
                </button>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <Sidebar
        onMouseLeave={() => !isCollapsed && setIsCollapsed(true)}
        collapsed={isCollapsed}
        rootStyles={SidebarStyles}
      >
        <LogoWrapper>
          {isCollapsed ? <Logo src={ShortLogo} alt="shortlogo" /> : <LogoBig src={BigLogo} alt="biglogo" />}
        </LogoWrapper>
        <Menu
          renderExpandIcon={({ open }) =>
            isCollapsed ? null : open ? <DownIcon click={null} /> : <DownIcon click={null} />
          }
        >
          {stateRoutes?.map((item, index) => (
            <div key={index}>
              <SubMenu
                onClick={() => handleOpenMenu(item)}
                active={checkActive(item)}
                rootStyles={SubMenuItemStyles}
                icon={getIcon(item)}
                label={item.nome}
                open={item.isOpen}
              >
                {item.menus.map((route, i) => (
                  <MenuItem onClick={() => onClickRoute(route.url)} rootStyles={MenuItemStyles}>
                    {route.label}
                  </MenuItem>
                ))}
              </SubMenu>
            </div>
          ))}
        </Menu>
      </Sidebar>
      <main className={classes.content}>
        <PageHeader />
        <Body>{children}</Body>
      </main>
    </div>
  );
}
