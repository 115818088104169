import React from 'react';

const AjuIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4841_12268)">
        <path
          d="M5.41699 8.33325H3.75033V14.1666H5.41699V8.33325ZM10.417 8.33325H8.75033V14.1666H10.417V8.33325ZM17.5003 15.8333H1.66699V17.4999H17.5003V15.8333ZM15.417 8.33325H13.7503V14.1666H15.417V8.33325ZM9.58366 0.833252L1.66699 4.99992V6.66658H17.5003V4.99992L9.58366 0.833252ZM5.24199 4.99992L9.58366 2.71659L13.9253 4.99992H5.24199Z"
          fill="#00616F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4841_12268">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AjuIcon;
